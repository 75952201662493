import "../styles/components/DashboardPage.scss";
import React, { useEffect, useMemo, useState } from "react";
import TableHeader from "./genericTable/TableHeader";
import { TableCard } from "../common/types/tableCardType";
import { t } from "i18next";
import { ReactComponent as DeviceIcon } from "../common/assats/homeScreens/cardsIcons/devices.svg";
import { ReactComponent as ActiveDeviceIcon } from "../common/assats/homeScreens/cardsIcons/activeDevices.svg";
import { ReactComponent as ExamsIcon } from "../common/assats/homeScreens/cardsIcons/exams.svg";
import { ReactComponent as FiltersIcon } from "../common/assats/icons/filters.svg";
import { ReactComponent as CalendarIcon } from "../common/assats/icons/calendar.svg";
import GraphsViewer from "./dashboard/GraphsViewer";
import { useQuery } from "@tanstack/react-query";
import { getAllBranchPatientsWithClinical } from "../common/api/apiCalls";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "./common/DatePicker/DatePicker";
import Filter from "./common/Filter/Filter";
import { useFilters } from "../common/contexts/FiltersContext";
import TableFilters from "./genericTable/TableFilters";
import { useToast } from "../common/contexts/useToast";

const DashboardPage = () => {
  const { t } = useTranslation();
    const { showToast } = useToast();
  const [selectedDates, setSelectedDates] = useState<Date | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const { activeFilters, handleFilters, clearFilters, handleCardFilters } =
    useFilters();
  const datePickerPlaceHolder = t("dashboard.allDates");

  useEffect(() => {
    fetchData()
  },[activeFilters])

  const [graphData, setGraphData] = useState<any>([]);
  const [patients, setPatients] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);

  const fetchData = async () => {
    const response = await getAllBranchPatientsWithClinical(activeFilters);
    if (response.data && response.success) {
      setGraphData(response.data.graphData);
      setPatients(response.data.patients);
      setSites(response.data.sites);
    } else {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
    }
  };

  const cards: TableCard[] = [
    {
      headline: "Total Devices",
      explanation: t("patientsList.cards.BeforeExamExplain"),
      icon: DeviceIcon,
      value: 105,
      action: () => {
        console.log("click");
      },
    },
    {
      headline: "Total Active Devices",
      explanation: t("patientsList.cards.afterExamExplain"),
      icon: ActiveDeviceIcon,
      value: 55,
      action: () => {
        console.log("click");
      },
    },
    {
      headline: t("Total Exam"),
      explanation: t("patientsList.cards.afterConsultationExplain"),
      icon: ExamsIcon,
      value: 280,
      action: () => {
        console.log("click");
      },
    },
  ];

  const navigateToDevicesWithFilters = () => {
    
  }

  const filtersObject = {
    siteStatus: {
      label: t("sitePage.sitesList"),
      options: sites?.map((site: string) => {
        return {
          name: site,
          label: site,
        };
      }),
    },
  };


  const cardsConfig: Array<{
    property: string;
    type: "circle" | "bar";
    title: string;
    barCount?: number;
  }> = [
    {
      property: "smokingStatus",
      type: "circle",
      title: t("dashboard.smoking"),
    },
    { property: "race", type: "circle", title: t("dashboard.race") },
    {
      property: "isSymptomatic",
      type: "circle",
      title: t("dashboard.symptomatic"),
    },
    { property: "gender", type: "circle", title: t("dashboard.gender") },
    { property: "BMI", type: "bar", title: t("dashboard.bmi"), barCount: 5 },
    { property: "age", type: "bar", title: t("dashboard.age"), barCount: 7 },
    {
      property: "triglycerides",
      type: "bar",
      title: t("dashboard.triglycerides"),
      barCount: 4,
    },
    {
      property: "bpSystolic",
      type: "bar",
      title: t("dashboard.bpSystolic"),
      barCount: 4,
    },
    {
      property: "totalCholesterol",
      type: "bar",
      title: t("dashboard.totalCholesterol"),
      barCount: 3,
    },
    {
      property: "sugarLevel",
      type: "bar",
      title: t("dashboard.glucose"),
      barCount: 3,
    },
  ];

  return (
    <div className="dashboard-page-main-container">
      <div className="table-header">
        <TableHeader cards={cards} />
      </div>
      <div className="dashboard-filters pt-3 d-flex">
        <div className="date-picker-container">
          {/* <CustomDatePicker
            name="date"
            placeHolder={datePickerPlaceHolder}
            handleBlur={() => {}}
            value={selectedDates}
            handleInputChange={(e) => setSelectedDates(e.target.value)}
            showErrors={false}
            handleFocus={() => {}}
            startIcon={<CalendarIcon />}
            isArrow={true}
          /> */}
          <Filter
            isOpen={isFilterOpen}
            filtersObject={filtersObject}
            handleFilterClose={() => {
              setIsFilterOpen(false);
            }}
          />
        </div>
        <div className="sites-filter-container">
          <TableFilters
            filtersText={t("general.filters.dashboardFilters")}
            isExport={true}
            exportAction={() => {
              console.log("export");
            }}
            filterAction={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
          />
        </div>
      </div>
      <div className="graph-container">
        <GraphsViewer
          dataSet={graphData ?? []}
          cardsConfig={cardsConfig}
        />
      </div>
    </div>
  );
};

export default DashboardPage;
