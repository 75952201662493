import React, { FC } from "react";
import MenuSideBar from "../pages/Menu";
import MainHome from "./MainHome";
import { homeScreenImages } from "../common/assats";
import { Outlet } from "react-router-dom";

const HomeOverlay = () => {
  return (
    <div className="main-home-container full-width-height">
      <div className="header global-gradient">
        <div className="logo">
          <img src={homeScreenImages.coraLogo} />
        </div>
      </div>
      <div className="content-container">
        <MenuSideBar />
        <Outlet />
      </div>
    </div>
  );
};

export default HomeOverlay;
