import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto"; // Ensure chart behavior
import "../../../styles/components/BarGraph.scss";
import { callback } from "chart.js/dist/helpers/helpers.core";
import { useTranslation } from "react-i18next";

interface BarGraphProps {
  objectsArray: Array<{ [key: string]: any }>;
  property: string;
  barCount?: number; // Optional prop to define the number of bars (ranges)
}

const BarGraph: React.FC<BarGraphProps> = ({
  objectsArray,
  property,
  barCount = 2,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { t } = useTranslation();
  // Step 1: Filter out undefined values for the desired property (BMI in this case)
  const filteredObjects = objectsArray.filter((obj) => obj[property]);
  const values = filteredObjects.map((obj) => Math.floor(obj[property])); // Ensure integers only

  // Step 2: Get the minimum and maximum values
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  // Step 3: Dynamically create ranges based on min and max values, using barCount
  const rangeSize = Math.ceil((maxValue - minValue + 1) / barCount); // Ensure ranges are integers

  const ranges = Array.from({ length: barCount }, (_, i) => {
    const rangeMin = minValue + i * rangeSize;
    const rangeMax = i === barCount - 1 ? maxValue : rangeMin + rangeSize; 
    return {
      label: i === barCount - 1
        ? `>= ${rangeMin.toFixed(1)}`
        : `${rangeMin.toFixed(1)} - ${rangeMax.toFixed(1)}`,
      min: rangeMin,
      max: rangeMax,
    };
  });

  // Step 4: Count how many values fall into each range
  const rangeCounts = ranges.map((range) => {
    return filteredObjects.reduce((count, obj) => {
      const value = obj[property];
      if (value >= range.min && value <= range.max) {
        return count + 1;
      }
      return count;
    }, 0);
  });

  const mainColor = "#2F75DA"; // The only color being used

  // Prepare the chart data with fading effects for non-hovered bars
  const dataToGraph = {
    labels: ranges.map((range) => range.label),
    datasets: [
      {
        data: rangeCounts,
        backgroundColor: ranges.map(
          (_, index) =>
            activeIndex === null || activeIndex === index
              ? mainColor
              : `rgba(47, 117, 218, 0.2)` // Adjust opacity for non-hovered bars
        ),
        borderRadius: 10, // Rounds the corners of the bars
        borderWidth: 1, // Adds border width around bars
        borderColor: "#fff",
        hoverBackgroundColor: ranges.map(
          () => `rgba(47, 117, 218, 0.8)` // Make hovered bar more visible
        ),
      },
    ],
  };

  // Chart options to handle hover behavior
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Disable the default color key
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const index = tooltipItem.dataIndex;
            const dataValue = tooltipItem.raw;
            let text = t("general.barGraph.bmiText")
              .replace("${label}", tooltipItem.label)
              .replace("${count}", dataValue);
            if (index === 0) {
            }
            return text;
          },
        },
        displayColors: false, // Disable color boxes entirely
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove the grid lines on the X-axis
        },
      },
      y: {
        beginAtZero: true, // Ensure Y-axis starts at 0
        grid: {
          display: false, // Remove the grid lines on the Y-axis
        },
      },
    },
    onHover: (event: any, chartElement: any[]) => {
      if (chartElement.length) {
        const index = chartElement[0].index;
        setActiveIndex(index);
      } else {
        setActiveIndex(null);
      }
    },
  };

  return (
    <div className="bar-graph-container">
      <Bar data={dataToGraph} options={options} width={500} height={300} />
    </div>
  );
};

export default BarGraph;
