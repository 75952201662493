export const getErrorMessage = (error: any): { message: string; redirectPath?: string } => {
    let errorMessage = "general.errors.generalError";
    let redirectPath: string | undefined = undefined;

    if (!error) {
        return { message: errorMessage, redirectPath };
    }

    // Check if the error has a response and an error code
    if (error.response) {
        const { status, data } = error.response;
        let errorCode = data?.error || "UNKNOWN_ERROR";

        // Map error codes to user-friendly messages (i18n) and redirection paths
        const errorMessages: Record<string, { message: string; redirectPath?: string }> = {
            // General errors
            "GE1": { message: "general.errors.generalError" },
            "GE2": { message: "general.errors.filledFieldsError" },

            // Site creation errors
            "SN1": { message: "sitePage.siteCreate.errors.siteNameExists" },
            "SN2": { message: "sitePage.siteCreate.errors.sitesFetchError" },

            // User section
            "U1": { message: "usersList.errors.userNotFound" },
            "U2": { message: "usersList.errors.userEmailExists" },
            "U3": { message: "usersList.errors.couldNotCreateUser" },
            "U4": { message: "usersList.errors.userPersonalIdExists" },
            "U5": { message: "usersList.errors.wrongOTP" },
            "U6": { message: "usersList.errors.wrongCredentials"  },
            "U7": { message: "usersList.errors.passwordChangeDoesntRequired", redirectPath: "/" },

            // Roles section
            "R1": { message: "sitePage.siteCreate.errors.rolesFetchError" },
            "R2": { message: "sitePage.siteCreate.errors.roleNotFound" },

            // Patient section
            "P1": { message: "patientsList.errors.patientNotFound" },
            "P2": { message: "patientsList.errors.patientEmailExists" },
            "P3": { message: "patientsList.errors.couldNotCreatePatient" },
            "P4": { message: "patientsList.errors.patientPersonalIdExists" },
        };

        const errorDetails = errorMessages[errorCode];
        if (errorDetails) {
            errorMessage = errorDetails.message;
            redirectPath = errorDetails.redirectPath;
        }
    }

    return { message: errorMessage, redirectPath };
};
