import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainHome from "../../components/MainHome";
import "../../styles/components/UserProfile.scss";
import ButtonCustom from "../../components/common/Button";
import { sysConfig } from "../../config";
import { useToast } from "../../common/contexts/useToast";
import CustomDropdown from "../../components/common/inputs/CustomDropdown";
import { Positions } from "../../components/common/general/positions";
import { FormBuilderInterface } from "../../components/common/FormBuilder";
import { updateUserProfile } from "../../common/api/apiCalls";
import useUserStore from "../../common/store/user.store";

const UserProfile = () => {
  const { user, updateUser, getUser } = useUserStore();
  const { t } = useTranslation();
  const { showToast } = useToast();

  const [userData, setUserData] = useState<any>({
    username: "",
    firstName: "",
    lastName: "",
    middleName: "",
    position: "",
    phoneNumber: null,
    password: "",
    fullName: "",
  });
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editValue, setEditValue] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const translatedPositions = Positions.map((e) => t(e));

  useEffect(() => {
    getUser();
    getUserData();
  }, []);

  const getUserData = async () => {
    setUserData({
      username: user?.username,
      firstName: user?.firstName,
      lastName: user?.lastName,
      middleName: user?.middleName,
      position: user?.position,
      phoneNumber: user?.phoneNumber,
      password: "",
      fullName: user?.fullName,
    });
  };

  const buttonSection = [
    {
      label: t("First name"),
      value: userData.firstName,
      key: "firstName",
      inputType: "text",
    },
    {
      label: t("Middle name"),
      value: userData.middleName,
      key: "middleName",
      inputType: "text",
    },
    {
      label: t("Last name"),
      value: userData.lastName,
      key: "lastName",
      inputType: "text",
    },
    {
      label: t("Position"),
      value: userData.position,
      key: "position",
      inputType: "text",
    },
    {
      label: t("Phone number"),
      value: userData.phoneNumber,
      key: "phoneNumber",
      inputType: "text",
    },
    {
      label: t("Change password"),
      value: "",
      key: "password",
      inputType: "password",
    },
  ];

  const handleEditClick = (key: string, currentValue: string) => {
    setEditingKey(key);
    setEditValue(currentValue);
  };

  const handleSaveClick = () => {
    let hasError = false;

    if (editingKey === "phoneNumber") {
      const isPhoneNumberValid = sysConfig.PHONE_NUMBER_PATTERN.test(editValue);
      if (!isPhoneNumberValid) {
        setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: true }));
        showToast("error", "Error", t("general.errors.phoneNumberError"));
        hasError = true;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: false }));
      }
    }

    if (!hasError) {
      setUserData({ ...userData, [editingKey!]: editValue });
      setEditingKey(null);
      setIsEdit(true);
    }
  };

  const handleCancelClick = () => {
    setEditingKey(null);
    setEditValue("");
    setErrors({});
  };

  const saveUserProfile = async () => {
    const res = await updateUserProfile({
      firstName: userData.firstName,
      middleName: userData.middleName,
      lastName: userData.lastName,
      position: userData.position,
      phoneNumber: userData.phoneNumber,
      password: userData.password,
      username: userData.username,
    });
    if (!res.success) {
      showToast("error", "Error", t(res.data.errorMessage));
    } else {
      showToast("success", "Success", t("userProfile.userUpdated"));
      setIsEdit(false);
      const userDataToSet = {
        firstName: userData.firstName,
        middleName: userData.middleName,
        lastName: userData.lastName,
        position: userData.position,
        phoneNumber: userData.phoneNumber,
        username: userData.username,
        fullName: `${userData.firstName} ${userData.middleName} ${userData.lastName}`,
      }
      updateUser(userDataToSet);
      setUserData(userDataToSet);
      getUser();
    }
  };

  return (
    <MainHome>
      <div className="user-profile-container">
        <div className="header-section pt-5 pb-3">
          <h2 className="fw-bold">{userData.fullName}</h2>
          <span>{userData.username}</span>
        </div>
        <hr />

        <div className="body-section mt-3">
          {buttonSection.map((item) => (
            <div
              className="d-flex justify-content-between align-items-center mb-4"
              key={item.key}
            >
              {editingKey === item.key ? (
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="w-75">
                      <label className="form-label">{item.label}</label>
                      {item.key === "position" ? (
                        <div className="custom-user-profile-dropdown mb-2">
                          <CustomDropdown
                            name={item.key}
                            label={item.label}
                            optionsArray={translatedPositions}
                            value={editValue}
                            handleInputChange={(e) =>
                              setEditValue(e.target.value)
                            }
                          />
                        </div>
                      ) : (
                        <input
                          type={item.inputType}
                          className={`form-control input mb-2 ${
                            errors[item.key] ? "border-danger" : ""
                          }`}
                          value={editValue}
                          onChange={(e) => setEditValue(e.target.value)}
                        />
                      )}
                      <ButtonCustom
                        onClick={handleSaveClick}
                        className="btn btn-primary"
                        context={t("Save")}
                        type="button"
                      />
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <button
                        onClick={handleCancelClick}
                        className="btn btn-link cancel-text d-flex align-items-center justify-content-end"
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div>
                    <h6 className="mb-0">{item.label}</h6>
                    <span className="text-muted">
                      {item.value || t("Label")}
                    </span>
                  </div>
                  <button
                    onClick={() => handleEditClick(item.key, item.value)}
                    className="btn btn-link text-primary p-0 d-flex align-items-center justify-content-end"
                  >
                    {t("Edit")}
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="footer-section">
          {!editingKey?.length && isEdit && (
            <ButtonCustom
              onClick={saveUserProfile}
              className="btn-primary"
              context={t("Save")}
              type="button"
            />
          )}
        </div>
      </div>
    </MainHome>
  );
};

export default UserProfile;
