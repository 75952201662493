import React, { useEffect, useState } from "react";
import { TextField, Chip, Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../common/contexts/useToast";
import "../../../styles/components/TextInput.scss";

export interface CustomChipInputProps {
  placeHolder?: string;
  validationRegex?: RegExp;
  customError?: string;
  ArrOfChips?: string[];
  onSubmit?: (value: string[]) => void;
}

const CustomChipInput: React.FC<CustomChipInputProps> = ({
  placeHolder = "general.placeholders.generalInputPlaceHolder",
  validationRegex,
  customError = "general.errors.invalidInput",
  ArrOfChips,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [chipOptions, setChipOptions] = useState<string[]>([]);
  const [currentChipOption, setCurrentChipOption] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (ArrOfChips && ArrOfChips.length) {
      setChipOptions(ArrOfChips);
    }
  }, []);

  const handleAddChip = () => {
    setIsError(false);

    if (validationRegex && !validationRegex.test(currentChipOption)) {
      setIsError(true);
      return;
    }
  
    if (chipOptions.includes(currentChipOption)) {
      setCurrentChipOption("");
      return;
    }
  
    const updatedChips = [...chipOptions, currentChipOption];
    setChipOptions(updatedChips);
    setCurrentChipOption("");
    onSubmit && onSubmit(updatedChips);
  };

  const handleRemoveEmail = (chip: string) => {
    const updatedChips = chipOptions.filter((e) => e !== chip);
    setChipOptions(updatedChips);
    onSubmit && onSubmit(updatedChips);
  };



  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddChip();
    }
  };

  return (
    <div className="custom-chip-input-container">
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 1,
            mt: 4,
          }}
        >
          <TextField
            variant="outlined"
            label={t(placeHolder)}
            value={currentChipOption}
            onChange={(e) => setCurrentChipOption(e.target.value)}
            fullWidth
            onKeyDown={handleKeyDown}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "&:hover fieldset": {
                  borderColor: "#1A5DBA",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0047AB",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#2F75DA",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#0047AB",
              },
            }}
          />
          {isError && <div className="invalid-input">{t(customError)}</div>}
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {chipOptions.map((email, index) => (
            <Chip
              key={index}
              label={email}
              onDelete={() => handleRemoveEmail(email)}
              color="primary"
              variant="outlined"
            />
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default CustomChipInput;
