import "../styles/components/DashboardPage.scss";
import { useEffect, useState } from "react";
import TableHeader from "./genericTable/TableHeader";
import { TableCard } from "../common/types/tableCardType";
import { ReactComponent as DeviceIcon } from "../common/assats/homeScreens/cardsIcons/devices.svg";
import { ReactComponent as ActiveDeviceIcon } from "../common/assats/homeScreens/cardsIcons/activeDevices.svg";
import { ReactComponent as ExamsIcon } from "../common/assats/homeScreens/cardsIcons/exams.svg";
import GraphsViewer from "./dashboard/GraphsViewer";
import { getAllBranchPatientsWithClinical } from "../common/api/apiCalls";
import { useTranslation } from "react-i18next";
import Filter from "./common/Filter/Filter";
import { useFilters } from "../common/contexts/FiltersContext";
import TableFilters from "./genericTable/TableFilters";
import { useToast } from "../common/contexts/useToast";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [selectedDates, setSelectedDates] = useState<Date | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const { activeFilters, handleFilters, clearFilters, handleCardFilters } =
    useFilters();
  const datePickerPlaceHolder = t("dashboard.allDates");

  useEffect(() => {
    fetchData();
  }, [activeFilters]);

  const [graphData, setGraphData] = useState<any>([]);
  const [patients, setPatients] = useState<any>([]);
  const [sites, setSites] = useState<any>([]);
  const [cards, setCards] = useState<TableCard[]>([]);

  const fetchData = async () => {
    const response = await getAllBranchPatientsWithClinical(activeFilters);
    if (response.data && response.success) {
      setGraphData(response.data.graphData);
      setPatients(response.data.patients);
      setSites(response.data.sites);
      setCardsData(
        response.data?.counts?.activeDevices,
        response.data?.counts?.deviceCount,
        response.data?.counts?.nonActiveDevices
      );
    } else {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
    }
  };

  const setCardsData = (
    activeDevices: number,
    deviceCount: number,
    nonActiveDevices: number
  ) => {
    setCards([
      {
        headline: t("dashboard.cards.totalDevices"),
        explanation: t("patientsList.cards.BeforeExamExplain"),
        icon: DeviceIcon,
        value: deviceCount,
        action: () => {
          navigateToDevicesWithFilters();
        },
      },
      {
        headline: t("dashboard.cards.totalActiveDevices"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: ActiveDeviceIcon,
        value: activeDevices,
        action: () => {
          navigateToDevicesWithFilters("status", "Active");
        },
      },
      {
        headline: t("dashboard.cards.totalExams"),
        explanation: t("patientsList.cards.afterConsultationExplain"),
        icon: ExamsIcon,
        value: nonActiveDevices,
        action: () => {
          console.log("click");
        },
      },
    ]);
  };

  const navigateToDevicesWithFilters = (
    filterName?: string,
    optionName?: string
  ) => {
    if (filterName && optionName) {
      navigate("/Home/Devices", {
        state: {
          filters: {
            key: filterName,
            value: optionName,
          },
        },
      });
      return;
    }
    navigate("/Home/Devices");
  };

  const filtersObject = {
    siteStatus: {
      label: t("sitePage.sitesList"),
      options: sites?.map((site: string) => {
        return {
          name: site,
          label: site,
        };
      }),
    },
  };

  const cardsConfig: Array<{
    property: string;
    type: "circle" | "bar";
    title: string;
    barCount?: number;
  }> = [
    {
      property: "smokingStatus",
      type: "circle",
      title: t("dashboard.smoking"),
    },
    { property: "race", type: "circle", title: t("dashboard.race") },
    {
      property: "isSymptomatic",
      type: "circle",
      title: t("dashboard.symptomatic"),
    },
    { property: "gender", type: "circle", title: t("dashboard.gender") },
    { property: "BMI", type: "bar", title: t("dashboard.bmi"), barCount: 5 },
    { property: "age", type: "bar", title: t("dashboard.age"), barCount: 7 },
    {
      property: "triglycerides",
      type: "bar",
      title: t("dashboard.triglycerides"),
      barCount: 4,
    },
    {
      property: "bpSystolic",
      type: "bar",
      title: t("dashboard.bpSystolic"),
      barCount: 4,
    },
    {
      property: "totalCholesterol",
      type: "bar",
      title: t("dashboard.totalCholesterol"),
      barCount: 3,
    },
    {
      property: "sugarLevel",
      type: "bar",
      title: t("dashboard.glucose"),
      barCount: 3,
    },
  ];

  return (
    <div className="dashboard-page-main-container">
      <div className="table-header">
        <TableHeader cards={cards} />
      </div>
      <div className="dashboard-filters pt-3 d-flex">
        <div className="date-picker-container">
          {/* <CustomDatePicker
            name="date"
            placeHolder={datePickerPlaceHolder}
            handleBlur={() => {}}
            value={selectedDates}
            handleInputChange={(e) => setSelectedDates(e.target.value)}
            showErrors={false}
            handleFocus={() => {}}
            startIcon={<CalendarIcon />}
            isArrow={true}
          /> */}
          <Filter
            isOpen={isFilterOpen}
            filtersObject={filtersObject}
            handleFilterClose={() => {
              setIsFilterOpen(false);
            }}
          />
        </div>
        <div className="sites-filter-container">
          <TableFilters
            filtersText={t("general.filters.dashboardFilters")}
            isExport={true}
            exportAction={(type: string) => {
              console.log(type);
            }}
            filterAction={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
          />
        </div>
      </div>
      <div className="graph-container">
        <GraphsViewer dataSet={graphData ?? []} cardsConfig={cardsConfig} />
      </div>
    </div>
  );
};

export default DashboardPage;
