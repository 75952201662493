import React, { useState } from "react";
import "../../../styles/components/AutoComplete.scss";

interface CustomAutoCompleteProps {
  name: string;
  label?: string;
  value: string | number;
  optionsArray: string[];
  required?: boolean;
  showErrors?: boolean;
  error?: string;
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  handleFocus?: React.FocusEventHandler<HTMLInputElement>;
  handleBlur?: (name: string) => void;
  withPlaceHolder?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = (props) => {
    const {
      name,
      label,
      value,
      optionsArray,
      required,
      showErrors,
      error,
      handleInputChange,
      handleFocus,
      handleBlur,
      withPlaceHolder = true,
      icon,
      disabled,
    } = props;
  
    const [filteredOptions, setFilteredOptions] = useState<string[]>(optionsArray);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
  
    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value.toLowerCase();
      const filtered = optionsArray.filter((option) =>
        option.toLowerCase().includes(searchValue)
      );
      setFilteredOptions(filtered);
      setShowDropdown(true);
      handleInputChange(event);
    };
  
    const handleSelect = (option: string) => {
      handleInputChange({
        target: { name, value: option },
      } as React.ChangeEvent<HTMLInputElement>);
      setShowDropdown(false);
    };
  
    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      setShowDropdown(true);
      handleFocus?.(e);
    };
  
    const handleInputBlur = () => {
      setIsFocused(false);
      setShowDropdown(false);
      handleBlur?.(name);
    };
  
    return (
      <div
        className={`autocomplete-wrapper ${
          value || isFocused ? "set-label" : ""
        } ${showErrors && error ? "invalid-field" : ""}`}
      >
        {withPlaceHolder && label && (
          <div className="custom-autocomplete-label">
            {label}
            {required && <div className="required-asterisk">*</div>}
          </div>
        )}
        <div className="input-wrapper">
          {icon && <span className="input-icon">{icon}</span>}
          <input
            type="text"
            name={name}
            value={value}
            onChange={handleFilter}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            disabled={disabled}
            className={`form-control ${showErrors && error ? "is-invalid" : ""}`}
          />
        </div>
        {showDropdown && (
          <ul className="autocomplete-options">
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                onMouseDown={() => handleSelect(option)}
                className="autocomplete-option"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
        {showErrors && error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  };
  

export default CustomAutoComplete;
