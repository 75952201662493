import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "../../../styles/components/CircleGraph.scss";
import hexToRgb from "../../../common/function/hexToRgb";

interface CircleGraphProps {
  objectsArray: Array<Record<string, any>>;
  property: string;
}

const CircleGraph: React.FC<CircleGraphProps> = ({
  objectsArray,
  property,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // Step 1: Filter out undefined values for the desired property
  const filteredObjects = objectsArray.filter(
    (obj) => obj[property] !== undefined
  );

  // Step 2: Count occurrences of each value
  const valueCounts = filteredObjects.reduce<Record<string, number>>(
    (acc, obj) => {
      const value = obj[property];
      const newValue =
        typeof value === "boolean" ? (value ? "Yes" : "No") : value;
      acc[newValue] = acc[newValue] ? acc[newValue] + 1 : 1;
      return acc;
    },
    {}
  );

  const totalObjects = objectsArray.length;

  // Step 3: Prepare the labels and values (percentages)
  const labels = Object.keys(valueCounts);
  const values = Object.values(valueCounts).map((count) =>
    ((count / totalObjects) * 100).toFixed(2)
  );

  const colors = [
    "#00C7BE",
    "#2F75DA",
    "#EE4AE7",
    "#6E33ED",
    "#AF52DE",
    "#F12889",
    "#FF9F09",
  ];

  // Prepare the chart data with fading effects for non-hovered segments
  const dataToGraph = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors.map(
          (color, index) =>
            activeIndex === null || activeIndex === index
              ? color
              : `rgba(${hexToRgb(color)}, 0.2)` // Adjust opacity for non-hovered segments
        ),
        hoverBackgroundColor: colors,
        borderRadius: 8, // Rounds the corners of the segments
        borderWidth: 5, // Adds spacing between segments
        borderColor: "#fff",
        hoverBorderColor: "#fff",
      },
    ],
  };

  // Chart options to handle hover behavior
  const options = {
    cutout: "68%",
    plugins: {
      legend: {
        display: false, // Disable the default color key
      },
      tooltip: {
        enabled: false,
      },
    },
    onHover: (event: any, chartElement: any[]) => {
      if (chartElement.length) {
        const index = chartElement[0].index;
        setActiveIndex(index);
      } else {
        setActiveIndex(null);
      }
    },
    onLeave: () => {
      setActiveIndex(null); // Reset active segment when leaving the chart
    },
  };

  return (
    <div className="circle-graph-container">
      <ul className={`legend-list ${labels.length > 5 ? "six-plus" : ""}`}>
        {labels.map((label, index) => (
          <li
            key={label}
            className="label-value-group"
            style={{
              opacity: activeIndex === null || activeIndex === index ? 1 : 0.5,
              transition: "opacity 0.3s ease",
            }}
          >
            <div className="label-color-group">
              <div
                className="color-marker"
                style={{
                  backgroundColor: colors[index],
                  opacity:
                    activeIndex === null || activeIndex === index ? 1 : 0.2,
                  transition: "opacity 0.3s ease",
                }}
              ></div>
              <span
                data-tooltip={label}
                title={label}
              >
                {label}
              </span>
            </div>
            <strong>{parseInt(values[index]).toFixed(0)}%</strong>
          </li>
        ))}
      </ul>
      <div className="graph" onMouseLeave={() => setActiveIndex(null)}>
        <Doughnut
          data={dataToGraph}
          options={options}
          className="doughnut-graph"
        />
      </div>
    </div>
  );
};

export default CircleGraph;
