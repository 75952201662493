import React, { useCallback, useEffect, useMemo, useState } from "react";
import MainHome from "../../components/MainHome";
import "./../../styles/homePage/patientsList.scss";
import TablePage from "../../components/genericTable/TablePage";
import { useTranslation } from "react-i18next";
import { TableCard } from "../../common/types/tableCardType";
import { ReactComponent as ActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/valid.svg";
import { ReactComponent as NonActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/cross.svg";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UserCreateInterface,
  UsersDataResponseInterface,
  UserTableData,
} from "../../common/interfaces/usersData.interface";
import {
  deleteUsers,
  exportData,
  getUsersData,
} from "../../common/api/apiCalls";
import Filter from "../../components/common/Filter/Filter";
import { sysConfig } from "../../config";
import { useToast } from "../../common/contexts/useToast";
import { Positions } from "../../components/common/general/positions";
import { userInfoFields } from "../../components/usersList/UserInfoFields";
import { useFilters } from "../../common/contexts/FiltersContext";
import { AxiosResponse } from "axios";
import { useDebouncedSearch } from "../../common/hooks/Search";
import { getRolesByRoleName } from "../../common/function/generalFunctions";
import { FilterOptionInterface } from "../../common/interfaces/filtersObject.interface";
import { RouteEnum } from "../../common/consts/roles";

type SortOptions =
  | "fullName"
  | "username"
  | "phoneNumber"
  | "status"
  | "relatedSite"
  | "position"
  | "role";

const sortKeysArray: SortOptions[] = [
  "fullName",
  "username",
  "phoneNumber",
  "status",
  "relatedSite",
  "position",
  "role",
];

const UsersList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search, debouncedSearch, handleSearchChange } = useDebouncedSearch(
    sysConfig.DEBOUNCE_TIME
  );
  const [sortKey, setSortKey] = useState<string>("username");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [usersCounts, setUsersCounts] = useState(0);
  const { activeFilters, clearFilters, handleCardFilters } = useFilters();
  const location = useLocation();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { showToast } = useToast();

  const [page, setPage] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [usersData, setUsersData] = useState<UserTableData[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [cards, setCards] = useState<TableCard[]>([]);

  const [filtersObject, setFiltersObject] = useState<FilterOptionInterface>({
    userStatus: {
      label: t("usersList.filters.userStatus"),
      options: [
        { name: t("sitePage.filters.active"), label: "Active" },
        { name: t("sitePage.filters.inactive"), label: "Inactive" },
      ],
    },
    relatedSite: {
      label: t("usersList.filters.relatedSite"),
      options: [],
    },
    positions: {
      label: t("usersList.filters.position"),
      options: Positions.map((position) => {
        return { name: t(position), label: t(position) };
      }).slice(0, -1),
    },
    role: {
      label: t("usersList.filters.role"),
      options: [] as { name: string; label: string }[],
    },
  });

  useEffect(() => {
    resetPagination();
  }, [location.pathname, sortKey, sortOrder, debouncedSearch]);

  const fetchData = async () => {
    try {
      const response = await getUsersData({
        sortKey,
        sortOrder,
        filters: activeFilters,
        search: debouncedSearch,
        page,
        limit: 10,
      });

      if (response?.data?.users?.length) {
        const filteredData = filterData(response.data.users);
        if (page === 1) {
          setUsersData(filteredData);
        } else {
          setUsersData((prevData) => [...prevData, ...filteredData]);
        }
        setUsersCounts(response.count || 0);
        setHasMore(response.data.users.length > 0);

        setUsersCounts((prev) => response.data.count);
        setCardsData(
          response.data?.activeUsersCount,
          response.data?.nonActiveUsersCount
        );
        setFiltersData(response.data?.sites);
      } else {
        setHasMore(false);
      }
    } catch (err) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
    } finally {
      setIsFetchingMore(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sortKey, sortOrder, activeFilters, debouncedSearch, page]);

  const setCardsData = (
    activeUsersCount: number,
    nonActiveUsersCount: number
  ) => {
    setCards([
      {
        headline: t("usersList.cards.ActiveUsers"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: ActiveSitesIcon,
        value: activeUsersCount,
        action: () => {
          handleCardFilters("userStatus", "Active");
          resetPagination();
        },
      },
      {
        headline: t("usersList.cards.nonActiveUsers"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: NonActiveSitesIcon,
        value: nonActiveUsersCount,
        action: () => {
          handleCardFilters("userStatus", "Inactive");
          resetPagination();
        },
      },
    ]);
  };

  const setFiltersData = (sites: string[]) => {
    const arrRoles = getRolesByRoleName("");
    setFiltersObject((prevFilters) => ({
      ...prevFilters,
      relatedSite: {
        ...prevFilters.relatedSite,
        options: sites.map((site: string) => ({
          name: site,
          label: site,
        })),
      },
      role: {
        ...prevFilters.role,
        options: arrRoles.map((role: string) => ({
          name: role,
          label: role,
        })),
      },
    }));
  };

  const resetPagination = () => {
    setPage(1);
    setUsersData([]);
    setHasMore(true);
    setIsFetchingMore(false);
  };

  const navigateToUserDetails = (row: any) => {
    const userData = usersData.find(
      (user: UserTableData) => user.username === row.username
    );
    if (!userData) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
      return;
    }
    const displayData = {
      position: userData?.position || "",
      role: userData.tableProps?.roles[0].name,
      email: userData.username,
      phoneNumber: userData?.phoneNumber || "",
      relatedSite: userData.tableProps?.sites?.[0]?.siteName || "",
    };
    navigate(`/Home/Users/View/${userData.tableProps.id}`, {
      state: {
        displayData: userInfoFields(displayData),
        editState: userData,
        informationData: {
          title: userData.fullName,
          status: userData.status,
          pathToEdit: `/Home/Users/Edit/${userData.tableProps.id}`,
          editPermission: RouteEnum.UsersEdit,
          userToEditRole:userData.tableProps?.roles[0].name
        },
      },
    });
  };

  const navigateToAddUser = () => {
    navigate("/Home/Users/Create");
  };

  const searchChangeHandler = (str: string) => {
    handleSearchChange(str);
    resetPagination();
  };

  const handleCheckBoxClick = (selectedRows: any) => {
    const rowId = selectedRows.tableProps.id;

    const newSelectedRows = usersData.map((row: any) => {
      //TODO remove any
      if (row.tableProps.id === rowId) {
        return {
          ...row,
          tableProps: {
            ...row.tableProps,
            selected: selectedRows.tableProps.selected,
          },
        };
      }
      return row;
    });

    setUsersData(newSelectedRows);
    if (selectedRows.tableProps.selected) {
      setSelectedRows((prev) => [...prev, selectedRows]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((row) => row.tableProps.id !== rowId)
      );
    }
  };


  const exportUsers = async (type: string) => {
    try {
      const params = {
        type: "users",
        formats: type.toLowerCase(),
      };
  
      const response = await exportData("export", params);
  
      if (response.success && response.data) {
        const axiosResponse = response.data as AxiosResponse<any, any>;
  
        const contentDisposition = axiosResponse.headers["content-disposition"];
        const contentType = axiosResponse.headers["content-type"];
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
          : contentType.includes("json")
          ? "export.json"
          : contentType.includes("spreadsheet")
          ? "export.xlsx"
          : "export.zip";
  
        const blob = new Blob([axiosResponse.data], { type: contentType });
  
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
  
        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        showToast(
          "error",
          t("general.toast.error"),
          t("general.export.exportGeneralError")
        );
      }
    } catch (error) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.export.exportGeneralError")
      );
    }
  };

  const handleFetchMore = () => {
    if (!isFetchingMore && hasMore) {
      setIsFetchingMore(true);
      setPage((prev) => prev + 1);
    }
  };

  const handleCheckAll = (e: any) => {
    const newSelectedRows = usersData.map((row: any) => {
      //TODO remove any
      return {
        ...row,
        tableProps: {
          ...row.tableProps,
          selected: e,
        },
      };
    });
    setUsersData(newSelectedRows);
    setSelectedRows(e ? usersData : []);
  };

  const filterData = (data: any) => {
    return data.map((user: UsersDataResponseInterface) => ({
      fullName: user.fullName,
      username: user.username,
      phoneNumber: user?.userInfo?.phoneNumber,
      status: user?.needToChangePassword ? "Inactive" : "Active",
      relatedSite: user.sites?.[0]?.siteName || "",
      position: user?.userInfo?.position || "",
      role: user.roles[0].name,
      tableProps: {
        id: user.id,
        selected: false,
        sites: user.sites,
        roles: user.roles,
        firstName: user?.userInfo?.firstName,
        lastName: user?.userInfo?.lastName,
        middleName: user?.userInfo?.middleName,
        phoneNumber: user?.userInfo?.phoneNumber,
        honorific: user?.userInfo?.honorific,
      },
    }));
  };

  return (
    <MainHome>
      <Filter
        isOpen={isFilterOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFilterOpen(false);
        }}
        filterTrigger={resetPagination}
      />
      <TablePage
        tableHeadline={t(`usersList.usersList`)}
        headerHeadline={t("usersList.userStatus")}
        cards={cards}
        hasMore={hasMore}
        isFetchingMore={isFetchingMore}
        fetchMore={handleFetchMore}
        createPath="UsersCreate"
        totalCount={usersCounts}
        viewAllAction={() => {
          clearFilters();
          resetPagination();
        }}
        filterAction={() => {
          setIsFilterOpen(!isFilterOpen);
        }}
        searchValue={search}
        setSearchValue={searchChangeHandler}
        searchPlaceholder={t("table.filter.searchByEmailOrUsername")}
        buttonText={t("usersList.addUser")}
        buttonAction={navigateToAddUser}
        setSortKey={setSortKey}
        sortKey={sortKey}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        tableData={usersData}
        handleCheckAll={(e) => {
          handleCheckAll(e);
        }}
        onCheckBoxClick={handleCheckBoxClick}
        sortKeysArray={
          sortKeysArray
            ? sortKeysArray.map((value) => {
                return { name: t(`usersList.sort.${value}`), val: value };
              })
            : []
        }
        onRowClick={(row: UsersDataResponseInterface) => {
          navigateToUserDetails(row);
        }}
        exportAction={exportUsers}
        isExport={true}
      />
    </MainHome>
  );
};

export default UsersList;
