import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainHome from "../components/MainHome";
import "../styles/components/DynamicPage.scss";
import { getAbout, getContact, getSupport } from "../common/api/apiCalls";
import { useTranslation } from "react-i18next";
import { useToast } from "../common/contexts/useToast";

const DynamicPage: React.FC = () => {
  const location = useLocation();
  const [isFromMain, setIsFromMain] = React.useState(false);
  const [isSubHeaderSection, setSubIsHeaderSection] = React.useState(false);
  const [htmlContent, setHtmlContent] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const { t } = useTranslation();
  const [aboutData, setAboutData] = React.useState<any>({
    releaseDate: "",
    swVersion: "",
  });
  const { showToast } = useToast();

  const swVersionContext = t("dynamicPage.about.swVersion");
  const releaseDateContext = t("dynamicPage.about.releaseDate");
  const subTitleContext = t("dynamicPage.about.secondTitle");

  useEffect(() => {
    const fromMain = location.pathname.includes("Home");
    setIsFromMain(fromMain);

    const locationType = fromMain
      ? location.pathname.split("/")[2]
      : location.pathname.split("/")[1];

    getData(locationType);
  }, [location.pathname]);

  const getData = async (type: string) => {
    switch (type.toLocaleLowerCase()) {
      case "about":
        await getAboutFunc();
        setTitle(t("dynamicPage.about.title"));
        break;
      case "support":
        await getSupportFunc();
        setTitle(t("dynamicPage.support"));
        break;
      case "contact":
        getContactFunc();
        setTitle(t("dynamicPage.contactUs"));
        break;
      case "contact":
      default:
    }
  };

  const getAboutFunc = async () => {
    const res = await getAbout();
    if (!res.success) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
      return;
    }
    setSubIsHeaderSection(true);
    setHtmlContent(res.html);
    setAboutData({
      releaseDate: res.releaseDate,
      swVersion: res.swVersion,
    });
  };

  const getSupportFunc = async () => {
    const res = await getSupport();
    if (!res.success) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
      return;
    }
    setHtmlContent(res.html);
  };

  const getContactFunc = async () => {
    const res = await getContact();
    if (!res.success) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
      return;
    }
    setHtmlContent(res.html);
  };

  return (
    <div className="dynamic-page">
      {isFromMain ? (
        <MainHome>
          {htmlContent && (
            <div className="dynamic-container">
              <div className="header-section">
                <h2 className="fw-bold">{title}</h2>
                <br />
                <hr />
              </div>

              {isSubHeaderSection && (
                <div className="sub-header-section">
                  <h2>{subTitleContext}</h2>
                  <br />
                  {swVersionContext + " " + aboutData.swVersion}
                  <br />
                  {releaseDateContext + " " + aboutData.releaseDate}
                  <br />
                  <br />
                  <hr />
                </div>
              )}
              <div className="iframe-container">
                <iframe
                  srcDoc={htmlContent}
                  title="Dynamic Content"
                  style={{
                    width: "100%",
                    height: "93%",
                    border: "none",
                  }}
                />
              </div>
            </div>
          )}
        </MainHome>
      ) : (
        <div className="iframe-container">
          {htmlContent && (
            <iframe
              srcDoc={htmlContent}
              title="Dynamic Content"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DynamicPage;
