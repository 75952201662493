import "../../styles/components/GraphsViewer.scss";
import GraphCard from "../common/GraphCard";

interface GraphsViewerProps {
  dataSet: Array<{ [key: string]: string | boolean | number }>;
  cardsConfig: Array<{
    property: string;
    type: "circle" | "bar";
    title: string;
    barCount?: number
  }>;
}

const GraphsViewer = (props: GraphsViewerProps) => {
  const { dataSet, cardsConfig } = props;

  return (
    <div className="graphs-viewer">
      {cardsConfig.map((cardConfig, i) => (
        <GraphCard
          key={i + cardConfig.property}
          type={cardConfig.type}
          property={cardConfig.property}
          barCount={cardConfig.barCount}
          title={cardConfig.title}
          objectsArray={dataSet}
        />
      ))}
    </div>
  );
};

export default GraphsViewer;
