import React, { SetStateAction, useEffect, useState } from "react";
// import DemographicsQuestions, {
//   FieldConfig,
// } from "../../common/DemographicsQuestions";

import {
  ClinicalInfoInterface,
  DemographicInfoInterface,
} from "../../../types/PatientData.interface";
import FormBuilder, {
  CustomDateChangeEvent,
  FormBuilderInterface,
} from "../../common/FormBuilder";
import {
  AddPatientFieldConfig,
  firstClinicalConditionalFunc,
  firstClinicalConditionalTrue,
} from "./AddPatientFieldConfigurations";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface AddPatientFormProps {
  currentStep: number;
  setPatientData: (values: any) => void;
  errors?: { [key: string]: string | null };
  checkForErrors?: (params: {
    checkAll?: boolean;
    name?: keyof (ClinicalInfoInterface & DemographicInfoInterface);
  }) => void;
  patientData: ClinicalInfoInterface & DemographicInfoInterface;
  showErrors: boolean;
  touched: { [key: string]: boolean };
  setTouched: (state: { [key: string]: boolean }) => void;
}

interface ClinicalQuestionInterface extends FormBuilderInterface {
  name: keyof ClinicalInfoInterface;
}

const AddPatientForm = (props: AddPatientFormProps) => {
  const {
    currentStep,
    setPatientData,
    patientData,
    showErrors,
    errors,
    checkForErrors,
    touched,
    setTouched,
  } = props;
  const { t } = useTranslation();

  const [fieldConfigArray, setFieldConfigArray] = useState([
    AddPatientFieldConfig.DEMOGRAPHICS,
    AddPatientFieldConfig.ADDRESS,
    AddPatientFieldConfig.CLINICAL_ONE,
    AddPatientFieldConfig.CLINICAL_TWO,
    AddPatientFieldConfig.CLINICAL_THREE,
    AddPatientFieldConfig.CLINICAL_FOUR,
  ]);

  const booleanDisplay = (value: string): boolean | string => {
    return value === "Yes" ? true : value === "No" ? false : value;
  };

  const handleUpdatePatientData = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | CustomDateChangeEvent
  ) => {
    const { name, value } = event.target;

    const formattedValue =
      value instanceof Date ? dayjs(value).format("YYYY-MM-DD") : value || "";
    setTouched({
      ...touched,
      [name]: true,
    });
    setPatientData((prevValues: any) => ({
      ...prevValues,
      [name]:
        name !== "smokingStatus"
          ? booleanDisplay(formattedValue)
          : formattedValue,
    }));
  };

  useEffect(() => {
    setFieldConfigArray((prevArray) => {
      const metrics = patientData?.metrics || "";
      const updatedArray = [...prevArray];
      updatedArray[currentStep] = updatedArray[currentStep].map(
        (field: any) => {
          if (field.name === "height") {
            return {
              ...field,
              label: `${
                metrics === "imperial"
                  ? t("patientsList.addPatient.secondClinical.feetAndInches")
                  : metrics === "metric"
                  ? t("patientsList.addPatient.secondClinical.meters")
                  : field.label
              }`,
            };
          }
          if (field.name === "weight") {
            return {
              ...field,
              label: `${
                metrics === "imperial"
                  ? t("patientsList.addPatient.secondClinical.pounds")
                  : metrics === "metric"
                  ? t("patientsList.addPatient.secondClinical.kilograms")
                  : field.label
              }`,
            };
          }
          return field;
        }
      );
      return updatedArray;
    });
  }, [currentStep, patientData?.metrics]);

  useEffect(() => {
    const isSymptomatic = !!patientData?.isSymptomatic;
    const isChestPain = !!patientData?.chestPain;

    const firstClinicalArr = [
      ...AddPatientFieldConfig.CLINICAL_ONE,
      ...firstClinicalConditionalFunc(isChestPain, isSymptomatic), 
    ];

    setFieldConfigArray((prevArray) => {
      return [
        AddPatientFieldConfig.DEMOGRAPHICS,
        AddPatientFieldConfig.ADDRESS,
        firstClinicalArr,
        AddPatientFieldConfig.CLINICAL_TWO,
        AddPatientFieldConfig.CLINICAL_THREE,
        AddPatientFieldConfig.CLINICAL_FOUR,
      ];
    });

    if (!isSymptomatic) {
      resetFirstClinicalFields();
    }
  }, [patientData?.isSymptomatic, patientData?.chestPain]);


  const resetFirstClinicalFields = () => {
    const fieldNamesToReset = firstClinicalConditionalFunc(true,true).map((field) => field.name);
  
    setPatientData((prevData:any) => {
      const updatedData = { ...prevData };
  
      fieldNamesToReset.forEach((fieldName) => {
        updatedData[fieldName] = null;
      });
  
      return updatedData;
    });
  };

  return (
    <div className="patient-form-container">
      <FormBuilder
        errors={errors}
        values={patientData}
        fields={fieldConfigArray[currentStep]}
        handleInputChange={(
          e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | CustomDateChangeEvent
        ) => {
          checkForErrors?.({
            checkAll: false,
            name: e.target.name as keyof (ClinicalInfoInterface &
              DemographicInfoInterface),
          });
          handleUpdatePatientData(e);
        }}
        showErrors={showErrors}
        handleBlur={(
          name?: keyof ClinicalInfoInterface | keyof DemographicInfoInterface
        ) => {
          checkForErrors?.({ checkAll: false });
        }}
      />
    </div>
  );
};

export default AddPatientForm;
