import { HonorificEnum } from "../../../common/enums/PatientEnums";
import { getCountriesArray } from "../../../common/function/getCountriesArr";
import {
  ClinicalInfoInterface,
  DemographicInfoInterface,
} from "../../../types/PatientData.interface";
import { FormBuilderInterface } from "../../common/FormBuilder";
import { ReactComponent as SearchIcon } from "../../../common/assats/table/search.svg";

export interface AddPatientFieldConfigInterface extends FormBuilderInterface {
  name: keyof (ClinicalInfoInterface & DemographicInfoInterface);
}

export const demographicsAddPatientFieldConfig: AddPatientFieldConfigInterface[] =
  [
    {
      name: "honorific",
      type: "dropdown",
      label: "patientsList.addPatient.demographics.honorific",
      optionsArray: Object.values(HonorificEnum),
      required: true,
      translateLabel: true,
    },
    {
      name: "gender",
      type: "dropdown",
      label: "patientsList.addPatient.demographics.gender",
      optionsArray: [
        "patientsList.addPatient.demographics.genderOptions.male",
        "patientsList.addPatient.demographics.genderOptions.female",
        "patientsList.addPatient.demographics.genderOptions.other",
      ],
      translateOptions: true,
      required: true,
      translateLabel: true,
    },
    {
      name: "birthday",
      type: "date",
      label: "patientsList.addPatient.demographics.dateOfBirth",
      required: true,
      translateLabel: true,
      maxDate: new Date(),
    },
    {
      name: "firstName",
      type: "text",
      label: "patientsList.addPatient.demographics.firstName",
      required: true,
      translateLabel: true,
    },
    {
      name: "middleName",
      type: "text",
      label: "patientsList.addPatient.demographics.middleName",
      translateLabel: true,
    },
    {
      name: "lastName",
      type: "text",
      label: "patientsList.addPatient.demographics.lastName",
      translateLabel: true,
      required: true,
    },
    {
      name: "email",
      type: "text",
      label: "patientsList.addPatient.demographics.email",
      translateLabel: true,
      required: true,
    },
    {
      name: "phoneNumber",
      type: "text",
      label: "patientsList.addPatient.demographics.phoneNumber",
      translateLabel: true,
      required: true,
    },
    {
      name: "maritalStatus",
      type: "dropdown",
      label: "patientsList.addPatient.demographics.maritalStatus",
      optionsArray: [
        "patientsList.addPatient.demographics.maritalStatusOptions.single",
        "patientsList.addPatient.demographics.maritalStatusOptions.married",
        "patientsList.addPatient.demographics.maritalStatusOptions.divorced",
        "patientsList.addPatient.demographics.maritalStatusOptions.widowed",
      ],
      translateOptions: true,
      offset: "4",
      translateOffset: true,
      required: true,
      translateLabel: true,
    },
    {
      name: "race",
      type: "dropdown",
      label: "patientsList.addPatient.demographics.race",
      optionsArray: [
        "patientsList.addPatient.demographics.raceOptions.americanIndianAlaskaNative",
        "patientsList.addPatient.demographics.raceOptions.asian",
        "patientsList.addPatient.demographics.raceOptions.blackAfricanAmerican",
        "patientsList.addPatient.demographics.raceOptions.hispanicLatino",
        "patientsList.addPatient.demographics.raceOptions.nativeHawaiianOtherPacificIslander",
        "patientsList.addPatient.demographics.raceOptions.white",
        "patientsList.addPatient.demographics.raceOptions.other",
      ],
      translateOptions: true,
      translateOffset: true,
      translateLabel: true,
      required: true,
    },
    {
      name: "medId",
      type: "number",
      label: "patientsList.addPatient.demographics.medId",
      translateLabel: true,
      offset: "4",
      translateOffset: true,
      required: true,
    },
    {
      name: "identityNumber",
      type: "text",
      label: "patientsList.addPatient.demographics.patientId",
      translateLabel: true,
      translateOffset: true,
      required: true,
    },
  ];

const secondClinical: AddPatientFieldConfigInterface[] = [
  {
    name: "smokingStatus",
    type: "dropdown",
    label: "patientsList.addPatient.secondClinical.smokingStatus",
    optionsArray: [
      "patientsList.addPatient.secondClinical.smokingStatusOptions.cessationOver5yrs",
      "patientsList.addPatient.secondClinical.smokingStatusOptions.cessationLessThan5yrs",
      "patientsList.addPatient.secondClinical.smokingStatusOptions.yesOverPackDay",
      "patientsList.addPatient.secondClinical.smokingStatusOptions.yesLessPackDay",
      "patientsList.addPatient.secondClinical.smokingStatusOptions.no",
    ],
    translateOptions: true,
    translateLabel: true,
  },
  {
    name: "takingOtherDrugs",
    type: "dropdown",
    label: "patientsList.addPatient.secondClinical.takingOtherDrugs",
    optionsArray: [
      "patientsList.addPatient.secondClinical.prescriptionMedicationsOptions.statins",
      "patientsList.addPatient.secondClinical.prescriptionMedicationsOptions.bpDrugs",
      "patientsList.addPatient.secondClinical.prescriptionMedicationsOptions.other",
    ],
    translateOptions: true,
    translateLabel: true,
  },
  {
    name: "sugarLevel",
    type: "text",
    label: "patientsList.addPatient.secondClinical.sugarLevel",
    translateLabel: true,
    suffix: "mg/dL",
  },
  {
    name: "totalCholesterol",
    type: "text",
    label: "patientsList.addPatient.secondClinical.totalCholesterol",
    translateLabel: true,
    suffix: "mg/dL",
  },
  {
    name: "ldl",
    type: "text",
    label: "patientsList.addPatient.secondClinical.ldl",
    translateLabel: true,
    suffix: "mg/dL",
  },
  {
    name: "triglycerides",
    type: "text",
    label: "patientsList.addPatient.secondClinical.triglycerides",
    translateLabel: true,
    suffix: "mg/dL",
  },
  {
    name: "bpSystolic",
    type: "text",
    label: "patientsList.addPatient.secondClinical.bpSystolic",
    translateLabel: true,
    suffix: "mmHg",
  },
  {
    name: "bpDiastolic",
    type: "text",
    label: "patientsList.addPatient.secondClinical.bpDiastolic",
    translateLabel: true,
    suffix: "mmHg",
  },
  {
    name: "height",
    type: "text",
    label: "patientsList.addPatient.secondClinical.height",
    translateLabel: true,
    offset: "4",
    translateOffset: true,
  },
  {
    name: "weight",
    type: "text",
    label: "patientsList.addPatient.secondClinical.weight",
    translateLabel: true,
    translateOffset: true,
  },
  {
    name: "notes",
    type: "text",
    label: "patientsList.addPatient.secondClinical.notes",
    translateLabel: true,
    colWidth: "col-12",
  },
];

const thirdClinical: AddPatientFieldConfigInterface[] = [
  {
    name: "copd",
    question: "patientsList.addPatient.thirdClinical.copd",
    optionsArray: [
      "patientsList.addPatient.thirdClinical.options.yes",
      "patientsList.addPatient.thirdClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "arrhythmiasOrConductionAbnormality",
    question:
      "patientsList.addPatient.thirdClinical.arrhythmiasOrConductionAbnormality",
    optionsArray: [
      "patientsList.addPatient.thirdClinical.options.yes",
      "patientsList.addPatient.thirdClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "peripheralArterialDisease",
    question: "patientsList.addPatient.thirdClinical.peripheralArterialDisease",
    optionsArray: [
      "patientsList.addPatient.thirdClinical.options.yes",
      "patientsList.addPatient.thirdClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "familyHistoryCardiovascularEvents",
    question:
      "patientsList.addPatient.thirdClinical.familyHistoryCardiovascularEvents",
    optionsArray: [
      "patientsList.addPatient.thirdClinical.options.yes",
      "patientsList.addPatient.thirdClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "cerebrovascularDisease",
    question: "patientsList.addPatient.thirdClinical.cerebrovascularDisease",
    optionsArray: [
      "patientsList.addPatient.thirdClinical.options.yes",
      "patientsList.addPatient.thirdClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "strokeOrTiaOrRIND",
    question: "patientsList.addPatient.thirdClinical.strokeOrTiaOrRIND",
    optionsArray: [
      "patientsList.addPatient.thirdClinical.options.yes",
      "patientsList.addPatient.thirdClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "chronicHeartFailure",
    question: "patientsList.addPatient.thirdClinical.chronicHeartFailure",
    optionsArray: [
      "patientsList.addPatient.thirdClinical.options.yes",
      "patientsList.addPatient.thirdClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
];

const fourthClinical: AddPatientFieldConfigInterface[] = [
  {
    name: "lvefLessThan30",
    question: "patientsList.addPatient.fourthClinical.lvefLessThan30",
    optionsArray: [
      "patientsList.addPatient.fourthClinical.options.yes",
      "patientsList.addPatient.fourthClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "previousCardiacEvent",
    question: "patientsList.addPatient.fourthClinical.previousCardiacEvent",
    optionsArray: [
      "patientsList.addPatient.fourthClinical.options.yes",
      "patientsList.addPatient.fourthClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "hemodynamicInstability",
    question: "patientsList.addPatient.fourthClinical.hemodynamicInstability",
    optionsArray: [
      "patientsList.addPatient.fourthClinical.options.yes",
      "patientsList.addPatient.fourthClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "pulmonaryEdema",
    question: "patientsList.addPatient.fourthClinical.pulmonaryEdema",
    optionsArray: [
      "patientsList.addPatient.fourthClinical.options.yes",
      "patientsList.addPatient.fourthClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "anemia",
    question: "patientsList.addPatient.fourthClinical.anemia",
    optionsArray: [
      "patientsList.addPatient.fourthClinical.options.yes",
      "patientsList.addPatient.fourthClinical.options.no",
    ],
    type: "radio",
    fullRow: true,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "otherCoMorbidities",
    label: "patientsList.addPatient.fourthClinical.otherCoMorbidities",
    type: "text",
    colWidth: "col-12",
    fullRow: true,
    translateLabel: true,
  },
];

const addressFields: AddPatientFieldConfigInterface[] = [
  {
    name: "address",
    type: "text",
    label: "Address (street name and house number)",
    colWidth: "12",
    required: true,
  },
  { name: "city", type: "text", label: "City", required: true },
  {
    name: "state",
    type: "text",
    label: "State",
  },
  { name: "zip", type: "text", label: "Zipcode", required: true },
  {
    name: "country",
    type: "autoComplete",
    optionsArray: getCountriesArray(true) as string[],
    label: "Country",
    required: true,
    icon: <SearchIcon />,
  },
];

const firstClinicalConditional: AddPatientFieldConfigInterface[] = [
  {
    name: "isSymptomatic",
    question: "Is the patient symptomatic?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
];

export const firstClinicalConditionalTrue: AddPatientFieldConfigInterface[] = [
  {
    name: "symptomsType",
    question: "Type of symptoms",
    optionsArray: ["Acute", "Chronic"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "chestPain",
    question: "Chest pain",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "shortnessOfBreath",
    question: "Shortness of breath",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "shoulderOrArmPain",
    question: "Shoulder or arm pain",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "jawPain",
    question: "Jaw pain",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "dizzinessOrPresyncope",
    question: "Dizziness/pre-syncope",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "nausea",
    question: "Nausea",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
];

const chestPainQuestions: AddPatientFieldConfigInterface[] = [
  {
    name: "chestPainType",
    question: "Type of chest pain",
    optionsArray: ["Typical", "Atypical"],
    type: "radio",
    nested: 1,
    fullRow: true,
  },
];

const questions: AddPatientFieldConfigInterface[] = [
  {
    name: "symptomsType",
    question: "Type of symptoms",
    optionsArray: ["Acute", "Chronic"],
    type: "radio",
    fullRow: true,
  },
];

const chestPainQuestion: AddPatientFieldConfigInterface[] = [
  {
    name: "chestPain",
    question: "Chest pain",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
];

export const firstClinicalConditionalFunc = (
  isChestPain: boolean,
  isSymptomatic: boolean
): AddPatientFieldConfigInterface[] => {
  if (!isSymptomatic) {
    return []; // If not symptomatic, return an empty array
  }

  const questions: AddPatientFieldConfigInterface[] = [
    {
      name: "symptomsType",
      question: "Type of symptoms",
      optionsArray: ["Acute", "Chronic"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "chestPain",
      question: "Chest pain",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    ...(isChestPain
      ? [
          ...chestPainQuestions, // Include chest pain type questions if chest pain is true
        ]
      : []),
    {
      name: "shortnessOfBreath",
      question: "Shortness of breath",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "shoulderOrArmPain",
      question: "Shoulder or arm pain",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "jawPain",
      question: "Jaw pain",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "dizzinessOrPresyncope",
      question: "Dizziness/pre-syncope",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "nausea",
      question: "Nausea",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
  ];

  return questions;
};

export const AddPatientFieldConfig = {
  DEMOGRAPHICS: demographicsAddPatientFieldConfig,
  ADDRESS: addressFields,
  CLINICAL_ONE: firstClinicalConditional,
  CLINICAL_TWO: secondClinical,
  CLINICAL_THREE: thirdClinical,
  CLINICAL_FOUR: fourthClinical,
};
