import React from "react";
import "../../../styles/components/TextInput.scss";

interface CustomTextAreaProps {
  value: string;
  required?: boolean;
  showErrors?: boolean;
  name: string;
  handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  handleFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  handleBlur?: (name: string) => void;
  translateErrors?: boolean;
  withPlaceHolder?: boolean;
  label?: string;
  error?: string;
  rows?: number; // Number of rows in the textarea
  maxLength?: number; // Maximum character length
  disabled?: boolean;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  value,
  name,
  required,
  showErrors,
  handleBlur,
  handleFocus,
  handleInputChange,
  label,
  translateErrors,
  withPlaceHolder = true,
  error,
  rows = 4,
  maxLength,
  disabled,
}) => {
  const setLabel = value;

  const getTextAreaClassName = () => {
    if (showErrors && error) {
      return "form-control custom-input is-invalid";
    }
    return `form-control custom-input`;
  };

  return (
    <>
      <div
        className={`input-group custom-input-base ${
          setLabel ? "set-label" : ""
        } position-relative ${error && showErrors ? "invalid-field" : ""}`}
      >
        {withPlaceHolder && label && (
          <div className={`custom-input-label-base custom-input-label`}>
            {label}
            {required && <div className="required-asterisk">*</div>}
          </div>
        )}
        <textarea
          name={name}
          id={name}
          rows={rows}
          maxLength={maxLength}
          value={value || ""}
          onChange={handleInputChange}
          onBlur={() => handleBlur && handleBlur(name)}
          onFocus={handleFocus}
          disabled={disabled}
          className={getTextAreaClassName()}
        />
      </div>
      {showErrors && error && (
        <div className="invalid-feedback">
          {translateErrors ? error : error}
        </div>
      )}
    </>
  );
};

export default CustomTextArea;
