import React from "react";
import "../../styles/homePage/table/tableRow.scss";
import { Form } from "react-bootstrap";
import { TableRowBase, TableRowProps } from "../../types/Table.interface";
import Settings from "../../pages/home/Settings";
import { ReactComponent as SettingsIcon } from "../../common/assats/table/settings.svg";
import { ReactComponent as BatteryIcon } from "../../common/assats/icons/batteryGreen.svg";

const TableRow = <T extends TableRowBase>(props: TableRowProps<T>) => {
  const { onRowClick, checkBoxClick, row } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedRow = {
      ...row,
      tableProps: {
        ...row.tableProps,
        selected: e.target.checked,
      },
    };

    checkBoxClick && checkBoxClick(updatedRow);
  };

  const keys = Object.keys(props.row).filter((key) => key !== "tableProps");
  return (
    <div className="table-row-main-container">
      <Form.Check
        inline
        id={row.tableProps?.id?.toLocaleString()}
        checked={row.tableProps?.selected || false}
        onChange={handleChange}
      />
      {keys.map((key, index) => (
        <div key={index + key.toLowerCase()} className={`table-cell`} title={(row as any)[key]}>
          {!key.includes("battery") ? (
            <span
              className={` ${
                (key.includes("status") || key.includes("Status")) &&
                !key.includes("_")
                  ? "rounded-status"
                  : ""
              }`}
            >
              {(row as any)[key] ?? "N/A"}
            </span>
          ) : (
            <span className="battery-container d-flex align-items-center justify-content-center">
              <span className="battery-icon me-2">
                <BatteryIcon />
              </span>
              {(row as any)[key] ? `${(row as any)[key]}%` : "N/A"}
            </span>
          )}
        </div>
      ))}
      <div className={`settings`}>
        <SettingsIcon
          className="settings-icon"
          onClick={() => onRowClick && onRowClick(row)}
        />
      </div>
    </div>
  );
};

export default TableRow;
