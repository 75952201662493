import React, { FC, useEffect } from "react";
import "../../styles/homePage/table/TableFilters.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as Trash } from "../../common/assats/table/trash.svg";
import { ReactComponent as FiltersIcon } from "../../common/assats/table/FiltersIcon.svg";
import { ReactComponent as ExportIcon } from "../../common/assats/table/export.svg";
import { ReactComponent as Search } from "../../common/assats/table/search.svg";
import { ReactComponent as Plus } from "../../common/assats/table/plus.svg";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useFilters } from "../../common/contexts/FiltersContext";
import { useLocation } from "react-router-dom";
import { canAccessRoute } from "../../common/function/roleRoutePermissions";
import useUserStore from "../../common/store/user.store";

const i18nPrefix = "table.filter";

export interface TableFiltersProps {
  tableHeadline?: string;
  trashAction?: () => void;
  filterAction?: () => void;
  searchValue?: string;
  setSearchValue?: (val: string) => void;
  searchPlaceholder?: string;
  buttonText?: string;
  buttonAction?: () => void;
  isExport?: boolean;
  exportAction?: () => void;
  filtersText?: string;
  createPath?: string;
}
const TableFilters: FC<TableFiltersProps> = ({
  tableHeadline,
  trashAction,
  filterAction,
  searchValue,
  setSearchValue,
  searchPlaceholder,
  buttonText,
  buttonAction,
  isExport = false,
  exportAction,
  filtersText,
  createPath,
}) => {
  const { t } = useTranslation();
  const { filtersCount } = useFilters();
  const filterTextContext = filtersText
    ? filtersText
    : t(`${i18nPrefix}.filters`);
  const [isAddDisabled, setIsAddDisabled] = React.useState(true);
  const location = useLocation();
  const { user } = useUserStore();

  useEffect(() => {
    setIsAddDisabledState();
  }, [location]);

  const setIsAddDisabledState = async () => {
    const role = user?.role;
    const path = createPath || location.pathname;
    const hasAccess = canAccessRoute(role, path);
    if (hasAccess) {
      setIsAddDisabled(false);
    }
  };

  const exportHandler = () => {
    if (exportAction) {
      exportAction();
    }
  }

  return (
    <div className="table-filters-main-container">
      {tableHeadline && (
        <div className="title-wrapper">
          <h3 className="patients-list-title">{tableHeadline}</h3>
        </div>
      )}

      <div className="filters-wrapper">
        {trashAction && (
          <div className="flex-center-container trash-button ">
            {/* <img className="image" src={menuIcons.arrow} /> */}
            <Trash onClick={trashAction} />
          </div>
        )}
        {filterAction && (
          <div
            onClick={filterAction}
            className={`flex-center-container filter-table-buttons filter-button p-3 ${
              filtersCount ? "active-filters-button " : ""
            }`}
          >
            {filtersCount ? (
              <span className="filters-count">{filtersCount}</span>
            ) : null}
            <FiltersIcon onClick={filterAction} />
            <span className="filters-text">{filterTextContext}</span>
          </div>
        )}

        {isExport && (
          <div
            className="flex-center-container temp filter-table-buttons p-3 export-button"
            onClick={exportHandler}
          >
            <ExportIcon />
          </div>
        )}

        {setSearchValue && (
          <Form.Group
            className="search-input-wrapper"
            controlId="formBasicEmail"
          >
            <InputGroup>
              <InputGroup.Text
                className="search-input"
                style={{ backgroundColor: "white", border: "none" }}
              >
                <Search />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder={searchPlaceholder}
                style={{ border: "none" }}
                className="search-input"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        )}

        {buttonAction && buttonText && !isAddDisabled && (
          <Button
            onClick={buttonAction}
            className="add-button d-flex align-items-center justify-content-center gap-2"
            variant="primary"
            type="button"
          >
            <Plus />
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TableFilters;
