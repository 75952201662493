export type Role = 'SuperAdmin' | 'SiteAdmin' | 'NonClinicalUser' | 'ClinicalUser';

export const roles = {
    SuperAdmin: 'SuperAdmin' as Role,
    SiteAdmin: "SiteAdmin" as Role,
    NonClinicalUser: 'NonClinicalUser' as Role,
    ClinicalUser: 'ClinicalUser' as Role,
};


export const roleDisplayNames = {
    SuperAdmin: 'Super Admin',
    SiteAdmin: 'Site Admin',
    ClinicalUser: 'Clinical User',
    NonClinicalUser: 'Non Clinical User',
}

export enum RouteEnum {
    Home = "Home",
    Dashboard = "Dashboard",
    Fota = "Fota",
    Devices = "Devices",
    DevicesCreate = "DevicesCreate",
    DevicesEdit = "DevicesEdit",
    Sites = "Sites",
    About = "About",
    Support = "Support",
    Contact = "Contact",
    Settings = "Settings",
    Users = "Users",
    UsersCreate = "UsersCreate",
    UsersEdit = "UsersEdit",
    SitesPageCreate = "SitesPageCreate",
    UserProfile = "UserProfile",
    Patient = "Patient",
    PatientCreate = "PatientCreate",
    PatientEdit = "PatientEdit",
}


export const roleRouteConfig: { [role in Role]: string[] } = {
    SuperAdmin: [
        RouteEnum.Home,
        RouteEnum.Dashboard,
        RouteEnum.Fota,
        RouteEnum.Devices,
        RouteEnum.DevicesCreate,
        RouteEnum.DevicesEdit,
        RouteEnum.Sites,
        RouteEnum.About,
        RouteEnum.Support,
        RouteEnum.Contact,
        RouteEnum.Settings,
        RouteEnum.Users,
        RouteEnum.UsersCreate,
        RouteEnum.UsersEdit,
        RouteEnum.SitesPageCreate,
        RouteEnum.UserProfile,
    ],
    SiteAdmin: [
        RouteEnum.Home,
        RouteEnum.Dashboard,
        RouteEnum.Patient,
        RouteEnum.PatientCreate,
        RouteEnum.PatientEdit,
        RouteEnum.Devices,
        RouteEnum.About,
        RouteEnum.Support,
        RouteEnum.Contact,
        RouteEnum.Settings,
        RouteEnum.Users,
        RouteEnum.UsersCreate,
        RouteEnum.UsersEdit,
        RouteEnum.UserProfile,
    ],
    ClinicalUser: [
        RouteEnum.Home,
        RouteEnum.Patient,
        RouteEnum.PatientCreate,
        RouteEnum.PatientEdit,
        RouteEnum.Fota,
        RouteEnum.About,
        RouteEnum.Support,
        RouteEnum.Contact,
        RouteEnum.Settings,
        RouteEnum.Sites,
        RouteEnum.Dashboard,
        RouteEnum.UserProfile,
    ],
    NonClinicalUser: [
        RouteEnum.Home,
        RouteEnum.About,
        RouteEnum.Support,
        RouteEnum.Patient,
        RouteEnum.PatientCreate,
        RouteEnum.PatientEdit,
        RouteEnum.Contact,
        RouteEnum.Settings,
        RouteEnum.UserProfile,
    ],
}