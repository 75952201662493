import { useCallback, useEffect, useState } from "react";
import MainHome from "../../components/MainHome";
import "./../../styles/homePage/patientsList.scss";
import TablePage from "../../components/genericTable/TablePage";
import { useTranslation } from "react-i18next";
import { TableCard } from "../../common/types/tableCardType";
import { ReactComponent as BeforeExam } from "../../common/assats/homeScreens/cardsIcons/beforeExam.svg";
import { ReactComponent as AfterExam } from "../../common/assats/homeScreens/cardsIcons/afterExam.svg";
import { ReactComponent as AfterConsultation } from "../../common/assats/homeScreens/cardsIcons/afterConsultation-2.svg";
import {
  deletePatients,
  getAllBranchPatients,
} from "../../common/api/apiCalls";
import { PatientDataInterface } from "../../common/types/patientDataType";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "../../components/common/Filter/Filter";
import { sysConfig } from "../../config";
import { NavigationPaths } from "../../common/consts/navigationPaths";
import { useFilters } from "../../common/contexts/FiltersContext";
import { useToast } from "../../common/contexts/useToast";
import { TableRowBase } from "../../types/Table.interface";
import { debounce } from "../../common/function/generalFunctions";

type SortOptions =
  | "patientName"
  | "identityNumber"
  | "phoneNumber"
  | "medId"
  | "examStatus"
  | "birthday"
  | "lastVisit"
  | "gender";

const PatientsList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [sortKey, setSortKey] = useState<string>("patientName");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const { activeFilters, clearFilters, handleCardFilters } = useFilters();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { showToast } = useToast();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [patientsData, setPatientsData] = useState<TableRowBase[]>([]);
  const [patientCounts, setPatientCounts] = useState(0);

  const [cards, setCards] = useState<TableCard[]>([]);

  useEffect(() => {
    resetPagination();
  }, [location.pathname, sortKey, sortOrder, debouncedSearch]);

  const filtersObject = {
    examStatus: {
      label: "Exam Status",
      options: [
        { name: "BeforeExam", label: "Before Exam" },
        { name: "AfterExam", label: "After Exam" },
        { name: "AfterConsultation", label: "After Consultation" },
      ],
    },
    lastVisit: {
      label: "Last Visit",
      options: [
        { name: "last7Days", label: "Last 7 Days" },
        { name: "last14Days", label: "Last 14 Days" },
        { name: "last30Days", label: "Last 30 Days" },
        { name: "last3Months", label: "Last 3 Months" },
        { name: "last12Months", label: "Last 12 Months" },
        { name: "custom", label: "Custom" },
      ],
      inputType: "date",
    },
    gender: {
      label: "Gender",
      options: [
        { name: "male", label: "Male" },
        { name: "female", label: "Female" },
      ],
    },
    dob: {
      label: "Date of Birth",
      options: [{ name: "custom", label: "Custom" }],
      inputType: "date",
    },
  };

  const getData = async () => {
    const response = await getAllBranchPatients({
      sortKey,
      sortOrder,
      filters: activeFilters,
      search: debouncedSearch,
      page,
      limit: 10,
    });

    if (response?.counts?.total) {
      const filteredData = filterData(response?.patients);
      setPatientsData((prevData) => [...prevData, ...filteredData]);
      setPatientCounts(response?.counts?.total);
      setIsFetchingMore(false);
      setCardsData(
        response?.counts?.beforeExam,
        response?.counts?.afterExam,
        response?.counts?.afterConsultation
      );
    } else if (!response?.counts?.total) {
      setHasMore(false);
      setIsFetchingMore(false);
    }
  };

  useEffect(() => {
    getData();
  }, [sortKey, sortOrder, activeFilters, debouncedSearch, page]);

  const setCardsData = (
    beforeExam: number,
    afterExam: number,
    afterConsultation: number
  ) => {
    setCards([
      {
        headline: "BeforeExam",
        explanation: t("patientsList.cards.BeforeExamExplain"),
        icon: BeforeExam,
        value: beforeExam,
        action: () => {
          handleCardFilters("examStatus", "BeforeExam");
          resetPagination();
        },
      },
      {
        headline: t("patientsList.cards.afterExam"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: AfterExam,
        value: afterExam,
        action: () => {
          handleCardFilters("examStatus", "AfterExam");
          resetPagination();
        },
      },
      {
        headline: t("patientsList.cards.afterConsultation"),
        explanation: t("patientsList.cards.afterConsultationExplain"),
        icon: AfterConsultation,
        value: afterConsultation,
        action: () => {
          handleCardFilters("examStatus", "AfterConsultation");
          resetPagination();
        },
      },
    ]);
  };

  const filterData = (data: any) => {
    return data.map((patient: PatientDataInterface) => {
      return {
        patientName: patient.firstName,
        identityNumber: patient.identityNumber,
        phoneNumber: patient.phoneNumber,
        medId: patient.medId,
        examStatus: t(`general.examStatus.${patient.examStatus}`),
        birthday: patient.birthday,
        lastVisit: patient.lastVisit
          ? new Date(patient.lastVisit).toLocaleDateString()
          : null,
        gender: patient.gender,
        tableProps: {
          id: patient.id,
          selected: false,
        },
      };
    });
  };

  const handleCheckBoxClick = (updatedRow: any) => {
    const rowId = updatedRow.tableProps.id;

    const updatedPatientsData = patientsData.map((row: any) =>
      row.tableProps.id === rowId
        ? {
            ...row,
            tableProps: {
              ...row.tableProps,
              selected: updatedRow.tableProps.selected,
            },
          }
        : row
    );

    setPatientsData(updatedPatientsData);
    if (updatedRow.tableProps.selected) {
      setSelectedRows((prev) => [...prev, updatedRow]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((row) => row.tableProps.id !== rowId)
      );
    }
  };

  const navigateToAddPatient = () => {
    navigate(NavigationPaths.PATIENTS + "/Create");
  };

  const sortKeysArray: SortOptions[] = [
    "patientName",
    "identityNumber",
    "phoneNumber",
    "medId",
    "examStatus",
    "birthday",
    "lastVisit",
    "gender",
  ];

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, sysConfig.DEBOUNCE_TIME),
    []
  );

  const handleSearchChange = (str: string) => {
    setSearch(str);
    debouncedSetSearch(str);
  };

  const resetPagination = () => {
    setPage(1);
    setPatientsData([]);
    setHasMore(true);
    setIsFetchingMore(false);
  };

  const handleFetchMore = () => {
    if (!isFetchingMore && hasMore) {
      setIsFetchingMore(true);
      setPage((prev) => prev + 1);
    }
  };

  const deleteSelectedPatients = async () => {
    const ids = selectedRows?.map((row) => row.tableProps?.id);
    if (!selectedRows.length || !ids.length) return;
    const response = await deletePatients(ids);
    if (response.success) {
      setSelectedRows([]);
      resetPagination();
      getData();
    } else {
      showToast(
        "error",
        t("general.delete.title"),
        t(response?.data?.errorMessage || "general.delete.error")
      );
    }
  };

  const handleCheckAll = (checked: boolean) => {
    const updatedPatientsData = patientsData.map((row: any) => {
      return {
        ...row,
        tableProps: {
          ...row.tableProps,
          selected: checked,
        },
      };
    });
    setPatientsData(updatedPatientsData);
    setSelectedRows(checked ? updatedPatientsData : []);
  };

  return (
    <MainHome>
      <Filter
        isOpen={isFiltersOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFiltersOpen(false);
        }}
        filterTrigger={resetPagination}
      />

      <TablePage
        hasMore={hasMore}
        isFetchingMore={isFetchingMore}
        fetchMore={handleFetchMore}
        tableHeadline={t(`patientsList.patientsList`)}
        headerHeadline={t("patientsList.patients")}
        viewAllAction={() => {
          clearFilters();
          resetPagination();
        }}
        cards={cards}
        createPath="PatientCreate"
        className={`${isFiltersOpen ? "filter-open" : ""}`}
        totalCount={patientCounts}
        trashAction={deleteSelectedPatients}
        onCheckBoxClick={handleCheckBoxClick}
        filterAction={() => {
          setIsFiltersOpen(!isFiltersOpen);
        }}
        searchValue={search}
        handleCheckAll={(e) => {
          handleCheckAll(e);
        }}
        setSearchValue={handleSearchChange}
        searchPlaceholder={t("patientsList.Search_by_Name_or_ID")}
        buttonText={t("patientsList.AddPatient")}
        buttonAction={() => {
          navigateToAddPatient();
        }}
        setSortKey={setSortKey}
        sortKey={sortKey}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        tableData={patientsData}
        sortKeysArray={
          sortKeysArray
            ? sortKeysArray.map((value) => {
                return { name: t(`patientsList.sort.${value}`), val: value };
              })
            : []
        }
        onRowClick={(row: PatientDataInterface) =>
          navigate(`${NavigationPaths.PATIENTS}/view/${row.identityNumber}`)
        }
      />
    </MainHome>
  );
};

export default PatientsList;
