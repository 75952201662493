import React, { useEffect, useMemo, useState } from "react";
import MainHome from "../../components/MainHome";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useFilters } from "../../common/contexts/FiltersContext";
import { useToast } from "../../common/contexts/useToast";
import { useQuery } from "@tanstack/react-query";
import { deleteDevice, getDevicesList } from "../../common/api/apiCalls";
import { ReactComponent as ActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/valid.svg";
import { ReactComponent as NonActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/cross.svg";
import { TableCard } from "../../common/types/tableCardType";
import Filter from "../../components/common/Filter/Filter";
import TablePage from "../../components/genericTable/TablePage";
import { DevicesTableDataInterface } from "../../common/interfaces/Devices.interface";
import { useDebouncedSearch } from "../../common/hooks/Search";
import { sysConfig } from "../../config";
import { deviceInfoFields } from "../../components/devices/DeviceInfoFields";
import { formatDate } from "../../common/function/formatDate";
import { FilterOptionInterface } from "../../common/interfaces/filtersObject.interface";
import { RouteEnum } from "../../common/consts/roles";

type SortOptions =
  | "deviceSerialNumber"
  | "relatedSuperUser"
  | "fw_Version"
  | "fw_Status"
  | "battery_Status"
  | "lastSeen"
  | "status";

const sortKeysArray: SortOptions[] = [
  "deviceSerialNumber",
  "relatedSuperUser",
  "fw_Version",
  "fw_Status",
  "battery_Status",
  "lastSeen",
  "status",
];

const Devices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { search, debouncedSearch, handleSearchChange } = useDebouncedSearch(
    sysConfig.DEBOUNCE_TIME
  );
  const [sortKey, setSortKey] = useState<string>("serialNumber");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [devicesCounts, setDevicesCounts] = useState(0);
  const { activeFilters, clearFilters, handleCardFilters } = useFilters();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { showToast } = useToast();
  const [devicesData, setDevicesData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [cards, setCards] = useState<TableCard[]>([]);
  const locationState = location.state;

  const [filtersObject, setFiltersObject] = useState<FilterOptionInterface>({
    relatedSite: {
      label: t("general.filters.relatedSite"),
      options: [],
    },
    relatedSuperUser: {
      label: t("devicesPage.filters.relatedSuperUser"),
      options: [],
    },
    fwVersionStatus: {
      label: t("devicesPage.filters.fwVersionStatus"),
      options: [],
    },
    fwVersionUpdateDate: {
      //NOTE tbd, add date picker
      label: t("devicesPage.filters.fwVersionUpdateDate"),
      options: [
        { name: "today", label: "Today" },
        { name: "yesterday", label: "Yesterday" },
        { name: "last7days", label: "Last 7 days" },
        { name: "last30days", label: "Last 30 days" },
        { name: "last3months", label: "Last 3 months" },
        { name: "last12months", label: "Last 12 months" },
        { name: "custom", label: "Custom" },
      ],
      inputType: "date",
    },
    status: {
      label: t("general.filters.status"),
      options: [
        { name: t("general.filters.active"), label: "Active" },
        { name: t("general.filters.inactive"), label: "Inactive" },
      ],
    },
  });

  useEffect(() => {
    if (locationState?.filters?.key && locationState?.filters?.value) {
      handleCardFilters(locationState.filters.key, locationState.filters.value);
      resetPagination();
    }
  }, [location.pathname, sortKey, sortOrder, debouncedSearch]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDevicesList({
        sortKey,
        sortOrder,
        filters: activeFilters,
        search: debouncedSearch,
        page,
        limit: 10,
      });
      if (response?.success && response?.data) {
        const filteredData = filterData(response.data.devices);
        if (locationState?.filters?.key) {
          setDevicesData(filteredData);
        } else {
          setDevicesData((prevData) => [...prevData, ...filteredData]);
        }
        setDevicesCounts(response?.data?.count);
        setIsFetchingMore(false);
        setFilterData(response.data?.sites, response.data?.users);
        setCardsData(
          response.data?.activeDevicesCount,
          response.data?.nonActiveDevicesCount
        );
      } else if (response?.data?.count === 0) {
        setHasMore(false);
        setIsFetchingMore(false);
      }
    };

    fetchData();
  }, [sortKey, sortOrder, activeFilters, debouncedSearch, page]);

  const setFilterData = (sites: string[], users: string[]) => {
    setFiltersObject((prevFilters) => ({
      ...prevFilters,
      relatedSite: {
        ...prevFilters.relatedSite,
        options: sites.map((site: string) => ({
          name: site,
          label: site,
        })),
      },
      relatedSuperUser: {
        ...prevFilters.relatedSuperUser,
        options: users.map((user: string) => ({
          name: user,
          label: user,
        })),
      },
    }));
  };

  const setCardsData = (
    activeDevicesCount: number,
    nonActiveDevicesCount: number
  ) => {
    setCards([
      {
        headline: t("devicesPage.cards.activeDevices"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: ActiveSitesIcon,
        value: activeDevicesCount || 0,
        action: () => {
          handleCardFilters("status", "Active");
          resetPagination();
        },
      },
      {
        headline: t("devicesPage.cards.nonActiveDevices"),
        explanation: t("patientsList.cards.afterExamExplain"),
        icon: NonActiveSitesIcon,
        value: nonActiveDevicesCount || 0,
        action: () => {
          handleCardFilters("status", "Inactive");
          resetPagination();
        },
      },
    ]);
  };

  const navigateToEdit = (deviceData: DevicesTableDataInterface) => {
    const device = devicesData.find(
      (device: any) => device.tableProps.id === deviceData.tableProps.id
    );
    if (!device) {
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
      return;
    }
    const displayData = {
      relatedSite: device.tableProps?.site,
      relatedSuperUser: device.relatedSuperUser,
      fw_status: device.fw_status,
      sw_version: device.sw_version,
      hwId: device.tableProps?.hwId,
    };

    navigate(`/Home/Devices/View/${deviceData.tableProps.id}`, {
      state: {
        displayData: deviceInfoFields(displayData),
        informationData: {
          title: device.deviceSerialNumber,
          batteryStatus: device.batteryStatus,
          pathToEdit: `/Home/Devices/Edit/${deviceData.tableProps.id}`,
          status: device?.status,
          editPermission: RouteEnum.DevicesEdit,
        },
        editState: device,
      },
    });
  };

  const filterData = (data: any) => {
    return data.map((device: any) => ({
      deviceSerialNumber: device.deviceSerialNumber,
      relatedSuperUser: device?.deviceAdmin?.username,
      fw_version: device.fwVersion,
      fw_status: device.firmwareStatus,
      battery_status: device.batteryStatus,
      lastSeen: device.lastSeen ? formatDate(device.lastSeen) : "N/A",
      status: device?.status,
      tableProps: {
        id: device.id,
        selected: false,
        site: device.site.siteName,
        hwId: device.hwId,
      },
    }));
  };

  const handleCheckBoxClick = (updatedRow: any) => {
    const rowId = updatedRow.tableProps.id;

    const updateRows = devicesData.map((row: any) => {
      if (row.tableProps.id === rowId) {
        return {
          ...row,
          tableProps: {
            ...row.tableProps,
            selected: updatedRow.tableProps.selected,
          },
        };
      }
      return row;
    });

    setDevicesData(updateRows);
    if (updatedRow.tableProps.selected) {
      setSelectedRows([...selectedRows, updatedRow]);
    } else {
      setSelectedRows(
        selectedRows.filter((row: any) => row.tableProps.id !== rowId)
      );
    }
  };

  const handleFetchMore = () => {
    if (!isFetchingMore && hasMore) {
      setIsFetchingMore(true);
      setPage((prev) => prev + 1);
    }
  };

  const resetPagination = () => {
    setPage(1);
    setDevicesData([]);
    setHasMore(true);
    setIsFetchingMore(false);
  };

  const handleCheckAll = (checked: boolean) => {
    const updatedRows = devicesData.map((row) => {
      return {
        ...row,
        tableProps: {
          ...row.tableProps,
          selected: checked,
        },
      };
    });

    setDevicesData(updatedRows);
    setSelectedRows(checked ? updatedRows : []);
  };

  return (
    <MainHome>
      <Filter
        isOpen={isFilterOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFilterOpen(false);
        }}
        filterTrigger={resetPagination}
      />
      <TablePage
        handleCheckAll={(e) => {
          handleCheckAll(e);
        }}
        tableHeadline={t(`devicesPage.title`)}
        headerHeadline={t("devicesPage.devices")}
        fetchMore={handleFetchMore}
        viewAllAction={() => {
          clearFilters();
          resetPagination();
        }}
        cards={cards}
        createPath="DevicesCreate"
        totalCount={devicesCounts}
        filterAction={() => {
          setIsFilterOpen(!isFilterOpen);
        }}
        searchValue={search}
        setSearchValue={handleSearchChange}
        searchPlaceholder={t("devicesPage.filters.search")}
        buttonText={t("devicesPage.create.title")}
        buttonAction={() => {
          navigate("/Home/Devices/Create");
        }}
        setSortKey={setSortKey}
        sortKey={sortKey}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        tableData={devicesData}
        onCheckBoxClick={handleCheckBoxClick}
        sortKeysArray={
          sortKeysArray
            ? sortKeysArray.map((value) => {
                return { name: t(`devicesPage.sort.${value}`), val: value };
              })
            : []
        }
        onRowClick={navigateToEdit}
      />
    </MainHome>
  );
};

export default Devices;
