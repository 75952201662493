import React, { SetStateAction, useEffect, useState } from "react";
// import DemographicsQuestions, {
//   FieldConfig,
// } from "../../common/DemographicsQuestions";

import {
  ClinicalInfoInterface,
  DemographicInfoInterface,
} from "../../../types/PatientData.interface";
import FormBuilder, {
  CustomDateChangeEvent,
  FormBuilderInterface,
} from "../../common/FormBuilder";
import { AddPatientFieldConfig } from "./AddPatientFieldConfigurations";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface AddPatientFormProps {
  currentStep: number;
  setPatientData: (values: any) => void;
  errors?: { [key: string]: string | null };
  checkForErrors?: (params: {
    checkAll?: boolean;
    name?: keyof (ClinicalInfoInterface & DemographicInfoInterface);
  }) => void;
  patientData: ClinicalInfoInterface & DemographicInfoInterface;
  showErrors: boolean;
  touched: { [key: string]: boolean };
  setTouched: (state: { [key: string]: boolean }) => void;
}

interface ClinicalQuestionInterface extends FormBuilderInterface {
  name: keyof ClinicalInfoInterface;
}

const AddPatientForm = (props: AddPatientFormProps) => {
  const {
    currentStep,
    setPatientData,
    patientData,
    showErrors,
    errors,
    checkForErrors,
    touched,
    setTouched,
  } = props;
  const { t } = useTranslation();

  const conditionalQuestions: ClinicalQuestionInterface[] = [];

  const firstClinical: ClinicalQuestionInterface[] = [
    {
      name: "isSymptomatic",
      question: "Is the patient symptomatic?",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    ...conditionalQuestions,
  ];

  const [fieldConfigArray, setFieldConfigArray] = useState([
    AddPatientFieldConfig.DEMOGRAPHICS,
    AddPatientFieldConfig.ADDRESS,
    firstClinical,
    AddPatientFieldConfig.CLINICAL_TWO,
    AddPatientFieldConfig.CLINICAL_THREE,
    AddPatientFieldConfig.CLINICAL_FOUR,
  ]);

  const booleanDisplay = (value: string): boolean | string => {
    return value === "Yes" ? true : value === "No" ? false : value;
  };

  const handleUpdatePatientData = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | CustomDateChangeEvent
  ) => {
    const { name, value } = event.target;

    const formattedValue =
      value instanceof Date ? dayjs(value).format("YYYY-MM-DD") : value || "";
    setTouched({
      ...touched,
      [name]: true,
    });
    setPatientData((prevValues: any) => ({
      ...prevValues,
      [name]:
        name !== "smokingStatus"
          ? booleanDisplay(formattedValue)
          : formattedValue,
    }));
  };

  const chestPainQuestions: ClinicalQuestionInterface[] = [
    {
      name: "chestPainType",
      question: "Type of chest pain",
      optionsArray: ["Typical", "Atypical"],
      type: "radio",
      nested: 1,
      fullRow: true,
    },
  ];

  // Add questions conditionally based on patientData
  if (patientData?.isSymptomatic) {
    conditionalQuestions.push(
      {
        name: "symptomsType",
        question: "Type of symptoms",
        optionsArray: ["Acute", "Chronic"],
        type: "radio",
        fullRow: true,
      },
      {
        name: "chestPain",
        question: "Chest pain",
        optionsArray: ["Yes", "No"],
        type: "radio",
        fullRow: true,
      },
      ...(patientData.chestPain ? chestPainQuestions : []), // Add nested chestPain questions if needed
      {
        name: "shortnessOfBreath",
        question: "Shortness of breath",
        optionsArray: ["Yes", "No"],
        type: "radio",
        fullRow: true,
      },
      {
        name: "shoulderOrArmPain",
        question: "Shoulder or arm pain",
        optionsArray: ["Yes", "No"],
        type: "radio",
        fullRow: true,
      },
      {
        name: "jawPain",
        question: "Jaw pain",
        optionsArray: ["Yes", "No"],
        type: "radio",
        fullRow: true,
      },
      {
        name: "dizzinessOrPresyncope",
        question: "Dizziness/pre-syncope",
        optionsArray: ["Yes", "No"],
        type: "radio",
        fullRow: true,
      },
      {
        name: "nausea",
        question: "Nausea",
        optionsArray: ["Yes", "No"],
        type: "radio",
        fullRow: true,
      }
    );
  }

  useEffect(() => {
    setFieldConfigArray((prevArray) => {
      const metrics = patientData?.metrics || "";
      console.log(metrics);
      const updatedArray = [...prevArray];
      updatedArray[currentStep] = updatedArray[currentStep].map(
        (field: any) => {
          if (field.name === "height") {
            return {
              ...field,
              label: `${
                metrics === "imperial"
                  ? t("patientsList.addPatient.secondClinical.feetAndInches")
                  : metrics === "metric"
                  ? t("patientsList.addPatient.secondClinical.meters")
                  : field.label
              }`,
            };
          }
          if (field.name === "weight") {
            return {
              ...field,
              label: `${
                metrics === "imperial"
                  ? t("patientsList.addPatient.secondClinical.pounds")
                  : metrics === "metric"
                  ? t("patientsList.addPatient.secondClinical.kilograms")
                  : field.label
              }`,
            };
          }
          return field;
        }
      );
      return updatedArray;
    });
  }, [currentStep, patientData?.metrics]);

  return (
    <div className="patient-form-container">
      <FormBuilder
        errors={errors}
        values={patientData}
        fields={fieldConfigArray[currentStep]}
        handleInputChange={(
          e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | CustomDateChangeEvent
        ) => {
          checkForErrors?.({
            checkAll: false,
            name: e.target.name as keyof (ClinicalInfoInterface &
              DemographicInfoInterface),
          });
          handleUpdatePatientData(e);
        }}
        showErrors={showErrors}
        handleBlur={(
          name?: keyof ClinicalInfoInterface | keyof DemographicInfoInterface
        ) => {
          checkForErrors?.({ checkAll: false });
        }}
      />
    </div>
  );
};

export default AddPatientForm;
