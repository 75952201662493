import * as yup from 'yup';
import { phoneNumberValidation } from './ValidationSchemas';
// 
const userCreationValidation = yup.object().shape({
  honorific: yup
    .string()
    .nullable(),
  firstName: yup
    .string()
    .required("usersList.usersListCreate.formErrors.firstNameRequired"),
  middleName: yup
    .string()
    .nullable(),
  lastName: yup
    .string()
    .required("usersList.usersListCreate.formErrors.lastNameRequired"),
  email: yup
    .string()
    .email("usersList.usersListCreate.formErrors.userEmailNotValid")
    .required("usersList.usersListCreate.formErrors.userEmailRequired"),
  phoneNumber: yup.string()
    .max(15, "Phone number must be at most 15 characters.")
    .required("Phone number is required.")
    .test(
      "is-valid-phone",
      "Phone number is not valid.",
      (value) => phoneNumberValidation(value)
    ),
  relatedSite: yup
    .string()
    .nullable(),
  position: yup
    .string()
    .nullable(),
  role: yup
    .string()
    .nullable(),
});

export default userCreationValidation;
