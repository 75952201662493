import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import "../../styles/loginStyles/setNewPassword.scss";

import { Link, useLocation, useNavigate } from "react-router-dom";
import HalfScreenLogo from "../../components/HalfScreenLogo";
import i18n from "../../i18n/i18n";
import { postSetFirstPassword } from "../../common/api/apiCalls";
import { useTranslation } from "react-i18next";
import { useToast } from "../../common/contexts/useToast";
import closedEye from "../../common/assats/input/eye-off.svg";
import openEye from "../../common/assats/input/eye-open.svg";
import { sysConfig } from "../../config";

const prefix = "set_new_password";

const SetNewPassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [capitalLetter, setCapitalLetter] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [number, setNumber] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [formErrors, setFormErrors] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  function testPassword(text: string) {
    const specialCharPattern = sysConfig.PASSWORD_SPECIAL_CHAR_PATTERN;
    const numberPattern = sysConfig.NUMBER_PATTERN;
    const capitalLetterPattern = sysConfig.CAPITAL_LETTER_PATTERN;

    const validations = {
      hasCapitalLetter: capitalLetterPattern.test(text),
      hasSpecialChar: specialCharPattern.test(text),
      hasNumber: numberPattern.test(text),
      isLongEnough: text.length >= 8,
    };

    setCapitalLetter(validations.hasCapitalLetter);
    setSpecialChar(validations.hasSpecialChar);
    setNumber(validations.hasNumber);
    setPasswordValid(
      validations.isLongEnough &&
        validations.hasCapitalLetter &&
        validations.hasSpecialChar &&
        validations.hasNumber
    );
  }

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (!password.length || !confirmPassword.length) {
      showToast("error", t("Error"), t(prefix + ".emptyFields"));
      setFormErrors({
        password: t(prefix + ".emptyFields"),
        confirmPassword: t(prefix + ".emptyFields"),
      });
      return;
    }

    if (!passwordValid) {
      showToast("error", t("Error"), t(prefix + ".errorInvalidPasswordShort"));
      return;
    }

    if (password !== confirmPassword) {
      setFormErrors({ confirmPassword: t(prefix + ".errorPasswordDontMatch") });
      showToast("error", t("Error"), t(prefix + ".errorPasswordDontMatch"));
      return;
    }

    const res = await postSetFirstPassword({
      password: password as string,
      confirmPassword: confirmPassword as string,
    });
    if (res.success) {
      if (location.state?.email) {
        navigate("/Success/reset");
      } else {
        navigate("/Success/password");
      }
    } else {
      showToast(
        "error",
        "Error",
        t(res?.data?.errorMessage?.message || "Error")
      );
      if (res?.data?.errorMessage?.redirectPath) {
        navigate(res?.data?.errorMessage?.redirectPath);
      }
    }
  };

  return (
    <HalfScreenLogo>
      <Col className="right-part d-flex align-items-center justify-content-center">
        <Form
          className="form-container d-flex align-items-center flex-column"
          onSubmit={onSubmit}
        >
          <Form.Group
            className="title-group"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Label className="global-big-label text-1o">
              {i18n.t("set_new_password.SIGN_IN_TEXT_MAIN_TITLE")}
            </Form.Label>
          </Form.Group>
          <Form.Group className="" controlId="formBasicEmail">
            {/* <Form.Control
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                testPassword(e.target.value);
              }}
              className="global-input"
              type={showPassword ? "text" : "password"}
              required
              placeholder={i18n.t(
                "set_new_password.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER"
              )}
            />
             */}
            <div className="password-container position-relative">
              <Form.Control
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  testPassword(e.target.value);
                }}
                className="global-input"
                type={showPassword ? "text" : "password"}
                placeholder={i18n.t(
                  "set_new_password.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER"
                )}
                required
              />
              <div
                className="reset-password-icon position-absolute bg-white"
                onClick={() => setShowPassword(!showPassword)}
              >
                {
                  <img
                    src={showPassword ? openEye : closedEye}
                    alt="pass-icon"
                  />
                }
              </div>
            </div>
            <Form.Text className="text-danger" style={{ marginLeft: "20px" }}>
              {formErrors.password}
            </Form.Text>
          </Form.Group>
          <Form.Group
            className="password-instructions-group"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Text className="password-instructions">
              {t(prefix + ".Password_must_be_at_least")}
              <Form.Text
                className={`reqText ${
                  password.length >= 8 ? "req-done-color" : "req-not-done-color"
                }`}
              >
                {" " + t(prefix + ".8_Characters")}
              </Form.Text>
              {" " + t(prefix + ".and_must_contain_at_least_a")}

              <Form.Text
                className={`reqText ${
                  capitalLetter ? "req-done-color" : "req-not-done-color"
                } `}
              >
                {" " + t(prefix + ".Capital_Letter")}
              </Form.Text>
              {" " + t(prefix + ".a")}
              <Form.Text
                className={`reqText ${
                  number ? "req-done-color" : "req-not-done-color"
                }`}
              >
                {" " + t(prefix + ".Number")}
              </Form.Text>
              {" " + t(prefix + ".and_a")}
              <Form.Text
                className={`reqText ${
                  specialChar ? "req-done-color" : "req-not-done-color"
                }`}
              >
                {" " + t(prefix + ".Special_Character")}
              </Form.Text>
            </Form.Text>
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicPassword">
            <div className="password-container position-relative">
              <Form.Control
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                className="global-input"
                type={showPassword ? "text" : "password"}
                placeholder={i18n.t(
                  "set_new_password.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER"
                )}
                required
              />
              <div
                className="reset-password-icon position-absolute bg-white"
                onClick={() => setShowPassword(!showPassword)}
              >
                {
                  <img
                    src={showPassword ? openEye : closedEye}
                    alt="pass-icon"
                  />
                }
              </div>
            </div>
            <Form.Text className="text-danger" style={{ marginLeft: "20px" }}>
              {formErrors.confirmPassword}
            </Form.Text>
          </Form.Group>

          <Form.Group className="checkbox-wrapper checkbox-wrapper">
            <Form.Check inline required />
            <Form.Label className="global-sub-label global-font-weight-600">
              {i18n.t("set_new_password.SIGN_IN_TEXT_BUTTON_2")}
            </Form.Label>
            <Link to="/ForgetPassword">
              <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer space underline">
                {i18n.t("set_new_password.SIGN_IN_TEXT_LABEL_1")}
              </Form.Label>
            </Link>
          </Form.Group>
          <Button
            className="global-button group-spacing"
            variant="primary"
            type="submit"
          >
            {i18n.t("set_new_password.SIGN_IN_TEXT_BUTTON_1")}
          </Button>
        </Form>
      </Col>
    </HalfScreenLogo>
  );
};

export default SetNewPassword;
