import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import "../../styles/loginStyles/login.scss";
import { Link, useNavigate } from "react-router-dom";
import HalfScreenLogo from "../../components/HalfScreenLogo";
import i18n from "../../i18n/i18n";
import { postLogin } from "../../common/api/apiCalls";
import { roles } from "../../common/consts/roles";
import closedEye from "../../common/assats/input/eye-off.svg";
import openEye from "../../common/assats/input/eye-open.svg";
import { FormErrors } from "../../common/types/formErrorType";
import { NavigationPaths } from "../../common/consts/navigationPaths";
import { useToast } from "../../common/contexts/useToast";
import { useTranslation } from "react-i18next";
import useUserStore from "../../common/store/user.store";
function Login() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState({ email: "", password: "" });
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useUserStore();

  const validateForm = () => {
    const errors: FormErrors = {};
    if (!formData.email) errors.email = "Email field is required";
    if (!formData.password) errors.password = "Password field is required";
    return errors;
  };
  const SIGN_IN_TEXT_MAIN_TITLE = i18n.t("login.SIGN_IN_TEXT_MAIN_TITLE");
  const SIGN_IN_TEXT_SUB_TITLE = i18n.t("login.SIGN_IN_TEXT_SUB_TITLE");
  const SIGN_IN_TEXT_INPUT_1_PLACEHOLDER = i18n.t(
    "login.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_INPUT_2_PLACEHOLDER = i18n.t(
    "login.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_BUTTON_1 = i18n.t("login.SIGN_IN_TEXT_BUTTON_1");
  const SIGN_IN_TEXT_BUTTON_2 = i18n.t("login.SIGN_IN_TEXT_BUTTON_2");
  const SIGN_IN_TEXT_LABEL_1 = i18n.t("login.SIGN_IN_TEXT_LABEL_1");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    // Validate form fields
    if(!formData.email.length || !formData.password.length) {
      showToast("error", t("general.toast.error"), t("general.errors.fillAllFields"));
      return;
    }

    // Call API
    const res = await postLogin({
      username: formData.email,
      password: formData.password,
    });
    if (
      res.success &&
      res?.data &&
      (res?.data?.userData || res?.data?.status)
    ) {
      const user = res.data.userData;
      setUser({
        id: user.id,
        username: user.username,
        role: [roles[user.role as keyof typeof roles]],
        fullName: user?.fullName ? user.fullName : user.firstName + " " + user.lastName,
        needToChangePassword: user.needToChangePassword,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        middleName:user?.middleName || "",
        position: user?.position || "",
        systemLanguage: user?.systemLanguage || "",
        predefinedEmails: user?.predefinedEmails || [],
      });
      if (user && user.needToChangePassword) {
        navigate("/Register/SetNewPassword", { replace: true });
      } else if (user && user?.role?.includes(roles.SuperAdmin)) {
        navigate(NavigationPaths.DASHBOARD, { replace: true });
      } else if (user && res?.data?.status === "2fa") {
        navigate(NavigationPaths.OTP, {
          state: { email: user.email, otpType: "login" },
          replace: true,
        });
      } else {
        navigate(NavigationPaths.PATIENTS, { replace: true });
      }
    } else if (res.errorMessage) {
      showToast("error", t("general.toast.error"), t(res.errorMessage));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <HalfScreenLogo>
      <Col className="right-part d-flex align-items-center justify-content-center">
        <Form className="form-container" onSubmit={onSubmit}>
          <Form.Group
            className="title-group"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Label className="global-big-label text-1o">
              {SIGN_IN_TEXT_MAIN_TITLE}
            </Form.Label>
            <Form.Label className="global-sub-label text-3o">
              {SIGN_IN_TEXT_SUB_TITLE}
            </Form.Label>
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Control
              onChange={handleChange}
              className="global-input"
              type="email"
              name="email"
              placeholder={SIGN_IN_TEXT_INPUT_1_PLACEHOLDER}
            />
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicPassword">
            <div className="password-container position-relative">
              <Form.Control
                onChange={handleChange}
                className="global-input"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter your password"
              />
              <div
                className="password-icon position-absolute bg-white"
                onClick={() => handleTogglePassword()}
                style={{
                  right: "1px",
                  paddingRight: "19px",
                  paddingLeft: "20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {
                  <img
                    src={showPassword ? openEye : closedEye}
                    alt="pass-icon"
                  />
                }
              </div>
            </div>
          </Form.Group>

          <Button
            className="global-button group-spacing"
            variant="primary"
            type="submit"
          >
            {SIGN_IN_TEXT_BUTTON_1}
          </Button>

          <Form.Group className="group-center">
            <Link to="/ForgetPassword">
              <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer">
                {SIGN_IN_TEXT_LABEL_1}
              </Form.Label>
            </Link>
          </Form.Group>
        </Form>
      </Col>
    </HalfScreenLogo>
  );
}

export default Login;
