import React, { useEffect } from "react";
import "./App.css";
import "./styles/app.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import SignIn from "./pages/register/SignIn";
import "bootstrap/dist/css/bootstrap.min.css";
import ForgetPassword from "./pages/loginScreens/ForgetPassword";
import PersonalInfo from "./pages/register/PersonalInfo";
import SetNewPassword from "./pages/register/SetNewPassword";
import Login from "./pages/loginScreens/Login";
import Success from "./components/Success";
import { useScrollBlock } from "./common/hooks/DisableScroll";
import NotAuthorized from "./pages/NotAuthorized";
import PatientsList from "./pages/home/PatientsList";
import Patient from "./components/Patient/Patient";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivateRoute from "./auth/PriveteRoute";
import Settings from "./pages/home/Settings";
import Dashboard from "./pages/home/Dashboard";
import Sites from "./pages/home/Sites";
import Devices from "./pages/home/Devices";
import Fota from "./pages/home/Fota";
import UsersList from "./pages/home/UsersList";
import ToastProvider from "./common/contexts/ToastProvider";
import SitesPageCreate from "./components/sites/SiteCreate";
import AddPatient from "./components/patientsList/addPatient/AddPatient";
import UserCreatePage from "./components/usersList/usersCreate";
import GeneralInformation from "./components/common/information/information";
import { FiltersProvider } from "./common/contexts/FiltersContext";
import HomeOverlay from "./components/HomeOverlay";
import DevicesCreate from "./components/devices/DevicesCreate";
import OTP from "./pages/loginScreens/OTP";
import DynamicPage from "./pages/DynamicPage";
import UserProfile from "./pages/home/UserProfile";

const queryClient = new QueryClient();
function App() {
  const [blockScroll] = useScrollBlock();
  const location = useLocation();


  useEffect(() => {
    blockScroll();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Routes>
          {/* Routes that don't require UserContextProvider */}
          <Route path={"Success/:type"} Component={Success} />
          <Route path={"NotAuthorized"} Component={NotAuthorized} />
          <Route path={"Register"}>
            <Route path={"SignIn/:token"} Component={SignIn} />
            <Route path={"PersonalInfo"} Component={PersonalInfo} />
            <Route path={"SetNewPassword"} Component={SetNewPassword} />
          </Route>
          <Route path={"/"} Component={Login} />
          <Route path={"ResetPassword"} Component={SetNewPassword} />
          <Route path={"ForgetPassword"} Component={ForgetPassword} />
          <Route path={"OTP"} Component={OTP} />
          <Route path={"Contact-us"} Component={DynamicPage} />
          <Route path={"About"} Component={DynamicPage} />
          <Route path={"Support"} Component={DynamicPage} />

          <Route
            path={"Home/"}
            element={
                <FiltersProvider key={location.pathname}>
                  <HomeOverlay />
                </FiltersProvider>
            }
          >
            <Route
              path={"Patients/view/:patientId"}
              element={
                <PrivateRoute component={Patient} componentName="Patient" />
              }
            />
            <Route
              path={"Patients/Create"}
              element={
                <PrivateRoute
                  component={AddPatient}
                  componentName="PatientCreate"
                />
              }
            />
            <Route
              path={"Patients/Edit/:patientId"}
              element={
                <PrivateRoute
                  component={AddPatient}
                  componentName="PatientEdit"
                />
              }
            />
            <Route
              path={"About"}
              element={
                <PrivateRoute component={DynamicPage} componentName="About" />
              }
            />
            <Route
              path={"Support"}
              element={
                <PrivateRoute component={DynamicPage} componentName="Support" />
              }
            />
            <Route
              path={"Contact"}
              element={
                <PrivateRoute component={DynamicPage} componentName="Contact" />
              }
            />
            <Route
              path={"Settings"}
              element={
                <PrivateRoute component={Settings} componentName="Settings" />
              }
            />
            <Route
              path={"Dashboard"}
              element={
                <PrivateRoute component={Dashboard} componentName="Dashboard" />
              }
            />
            <Route
              path={"Patients"}
              element={
                <PrivateRoute
                  component={PatientsList}
                  componentName="Patient"
                />
              }
            />
            <Route
              path={"Sites"}
              element={<PrivateRoute component={Sites} componentName="Sites" />}
            />
            <Route
              path={"Sites/Create"}
              element={
                <PrivateRoute
                  component={SitesPageCreate}
                  componentName="SitesPageCreate"
                />
              }
            />
            <Route
              path={"Sites/View/:id"}
              element={
                <PrivateRoute
                  component={GeneralInformation}
                  componentName="SitesPageCreate"
                />
              }
            />
            <Route
              path={"Sites/Edit/:id"}
              element={
                <PrivateRoute
                  component={SitesPageCreate}
                  componentName="SitesPageCreate"
                />
              }
            />
            <Route
              path={"Users"}
              element={
                <PrivateRoute component={UsersList} componentName="Users" />
              }
            />
            <Route
              path={"Users/Create"}
              element={
                <PrivateRoute
                  component={UserCreatePage}
                  componentName="UsersCreate"
                />
              }
            />
            <Route
              path={"Users/View/:id"}
              element={
                <PrivateRoute
                  component={GeneralInformation}
                  componentName="UsersCreate"
                />
              }
            />
            <Route
              path={"Users/Edit/:id"}
              element={
                <PrivateRoute
                  component={UserCreatePage}
                  componentName="UsersEdit"
                />
              }
            />
            <Route
              path={"Devices"}
              element={
                <PrivateRoute component={Devices} componentName="Devices" />
              }
            />
            <Route
              path={"Devices/Create"}
              element={
                <PrivateRoute
                  component={DevicesCreate}
                  componentName="DevicesCreate"
                />
              }
            />
            <Route
              path={"Devices/View/:id"}
              element={
                <PrivateRoute
                  component={GeneralInformation}
                  componentName="UsersCreate"
                />
              }
            />
            <Route
              path={"Devices/Edit/:id"}
              element={
                <PrivateRoute
                  component={DevicesCreate}
                  componentName="DevicesEdit"
                />
              }
            />
            <Route
              path={"Fota"}
              element={<PrivateRoute component={Fota} componentName="Fota" />}
            />
            <Route
              path={"UserProfile"}
              element={
                <PrivateRoute
                  component={UserProfile}
                  componentName="UserProfile"
                />
              }
            />
          </Route>
        </Routes>
      </ToastProvider>
    </QueryClientProvider>
  );
}

export default App;
