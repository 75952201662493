
import axios, { AxiosResponse, Method } from 'axios';
import Cookies from 'js-cookie';

// const urlsPath = "http://localhost:8000/api/v1/webapp";
// const urlsPath = "https://dev.api.acculine-clinical.com/api/v1/webapp";//for dev

const unauthorizedExertionRoutes = ["/register", "/", "/resetPassword", "/forgetpassword", "/otp", "/notauthorized", "/setnewpassword", "/signin", "/personalinfo"];

const urlsPath = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:8000/api/v1/webapp";

let refreshToken: string | null = null;
let patientIndex: string | null = null;
let otpToken: string | null = null;


const apiInstance = axios.create({
    timeout: 10000,
    headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
    },
    withCredentials: true,
});


apiInstance.interceptors.response.use(
    response => response,
    async error => {
        if (error.response?.status === 403 || error.response?.status === 401) {
            localStorage.removeItem("access_token");
            Cookies.remove("access_token");
            if (!unauthorizedExertionRoutes.includes(window.location.pathname.toLocaleLowerCase())) {
                window.location.href = "/";
            }
        }
        return Promise.reject(error);
    }
);


setAPIBaseURL()

const setRefreshedAuthData = async (authToken: string, rToken: string, patientI?: string) => {
    setAPIAuthToken(authToken);
    setAPIRefreshToken(rToken);
    if (patientI)
        patientIndex = patientI
};

function setAPIAuthToken(authToken: string) {
    apiInstance.defaults.headers.common.Authorization = `${authToken}`;
}

const setAPIRefreshToken = async (rToken: string) => {
    refreshToken = `${rToken}`;
    await localStorage.setItem("refreshToken", rToken)
};

function removeAPIAuthToken() {
    refreshToken = null
    delete apiInstance.defaults.headers.common.Authorization;
}

function setAPITimeout(timeout: number) {
    apiInstance.defaults.timeout = timeout;
}

function setAPIBaseURL() {
    apiInstance.defaults.baseURL = urlsPath;
}

async function API_Root(url: string, method: Method, data: any) {
    const dataJson = JSON.stringify(data, null, 2);
    try {
        return await apiInstance
            .request({
                url,
                method,
                data: dataJson,
                validateStatus: function (status_1) {
                    return (status_1 >= 200 && status_1 < 300) || (status_1 >= 400 && status_1 < 500) || status_1 == 504;
                },
            });
    } catch (error: any) {
        return await Promise.reject(error);
    }
}




/*=============================GENERIC FUNCTIONS======================================*/

async function makePostRequest(url: string, data: any) {
    try {
        const res = await apiInstance
            .request({
                url,
                method: "POST",
                data,
            });
        if (url === "/users/Login") {
            await localStorage.setItem("access_token", res?.data?.data?.access_token)
        }

        return res
    } catch (error) {
        return await Promise.reject(error);
    }
}

async function makeGetRequest(url: string, params: any = undefined) {
    try {
        return await apiInstance
            .request({
                url,
                method: "GET",
                params,
            });
    } catch (error) {
        return await Promise.reject(error);
    }
}

async function makeExportRequest(url: string, params: any = undefined) {
    try {
        return await apiInstance
            .request({
                url,
                method: "POST",
                responseType: "blob",
                params,
            });
    } catch (error) {
        return await Promise.reject(error);
    }
}

async function makePostRequestForDownload(url: string, data: any): Promise<AxiosResponse<any>> {
    try {
        const res = await apiInstance.request({
            url,
            method: "POST",
            data,
            responseType: "arraybuffer",
        });
        return res;
    } catch (error) {
        return await Promise.reject(error);
    }
}

async function makeDeleteRequest(url: string, data: any = {}) {
    try {
        return await apiInstance
            .request({
                url,
                method: "DELETE",
                data,
            });
    } catch (error) {
        return await Promise.reject(error);
    }
}

async function makeUploadRequest(url: string, data: any) {
    try {
        return await apiInstance
            .request({
                url,
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data,
                validateStatus: (status_1) => (status_1 >= 200 && status_1 < 300) || (status_1 >= 400 && status_1 < 500),
            });
    } catch (error) {
        return await Promise.reject(error);
    }
}


export {
    urlsPath,
    API_Root,
    makeGetRequest,
    makePostRequest,
    setAPIRefreshToken,
    setAPIAuthToken,
    removeAPIAuthToken,
    setAPITimeout,
    makeUploadRequest,
    makeDeleteRequest,
    setRefreshedAuthData,
    refreshToken,
    patientIndex,
    makeExportRequest,
    makePostRequestForDownload
};