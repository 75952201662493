import { useState, useCallback } from "react";
import { debounce } from "../function/generalFunctions";

export const useDebouncedSearch = (delay: number) => {
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, delay),
    [delay]
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
    debouncedSetSearch(value);
  };

  return { search, debouncedSearch, handleSearchChange };
};