import { GenderEnum, HonorificEnum, MaritalStatusEnum } from "../enums/PatientEnums";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import * as Yup from "yup";
import { ClinicalInfoInterface, DemographicInfoInterface } from "../../types/PatientData.interface";

export enum StepsEnum {
  DEMOGRAPHICS = 0,
  ADDRESS = 1,
  CLINICAL_ONE = 2,
  CLINICAL_TWO = 3,
  CLINICAL_THREE = 4,
  LAST_STEP = 5,
}

export const phoneNumberValidation = (value: string) => {
  if (!value) return false;
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber ? phoneNumber.isValid() : false;
};

// Refactor validationSchemas into a function
const getValidationSchemas = (
  patientData: ClinicalInfoInterface & DemographicInfoInterface
): Record<StepsEnum, Yup.ObjectSchema<any>> => {
  return {
    [StepsEnum.DEMOGRAPHICS]: Yup.object().shape({
      honorific: Yup.mixed()
        .oneOf(Object.values(HonorificEnum), "Invalid honorific.")
        .required("Honorific is required."),

      firstName: Yup.string()
        .max(20, "First name must be at most 20 characters.")
        .required("First name is required."),

      medId: Yup.number()
        .typeError("medId must be a valid number.")
        .required("medId is required")
        .positive("medId must be a positive number."),

      identityNumber: Yup.string()
        .min(8, "Identity number must be at least 8 characters.")
        .max(15, "Identity number must not exceed 15 characters.")
        .required("Identity number is required."),

      lastName: Yup.string()
        .max(20, "Last name must be at most 20 characters.")
        .required("Last name is required."),

      birthday: Yup.date()
        .required("Birthday is required.")
        .typeError("Invalid date for birthday.")
        .max(new Date(), "Birthday cannot be in the future."),

      gender: Yup.mixed()
        .oneOf(Object.values(GenderEnum), "Invalid gender.")
        .required("Gender is required."),

      phoneNumber: Yup.string()
        .max(15, "Phone number must be at most 15 characters.")
        .required("Phone number is required.")
        .test(
          "is-valid-phone",
          "Phone number is not valid.",
          (value) => phoneNumberValidation(value)
        ),

      email: Yup.string()
        .email("Email must be a valid format.")
        .max(50, "Email must be at most 50 characters.")
        .required("Email is required."),

      middleName: Yup.string()
        .max(20, "Middle name must be at most 20 characters.")
        .nullable(),

      maritalStatus: Yup.mixed()
        .oneOf(Object.values(MaritalStatusEnum), "Marital Status required")
        .required("Marital Status required"),

      race: Yup.mixed()
        .oneOf(
          [
            "American Indian / Alaska Native",
            "Asian",
            "Black / African American",
            "Hispanic / Latino",
            "Native Hawaiian / Other Pacific Islander",
            "White",
            "Other",
          ],
          "Unrecognized race"
        )
        .required("Race should not be empty."),
    }),

    [StepsEnum.ADDRESS]: Yup.object().shape({
      state: Yup.string().max(20, "State must be at most 20 characters."),

      address: Yup.string()
        .max(50, "Address must be at most 50 characters.")
        .required("Address is required."),

      city: Yup.string()
        .max(20, "City must be at most 20 characters.")
        .required("City is required."),

      zip: Yup.string()
        .max(10, "ZIP code must be at most 10 characters.")
        .required("ZIP code is required."),

      country: Yup.string().required("Country is required."),
    }),

    [StepsEnum.CLINICAL_ONE]: Yup.object().shape({
      isSymptomatic: Yup.boolean().required("Symptomatic status is required."),

      // Validate symptom-related questions only if `isSymptomatic` is `true`
      ...(patientData.isSymptomatic
        ? {
            symptomsType: Yup.mixed()
              .oneOf(["Acute", "Chronic"], "Symptoms type must be Acute or Chronic.")
              .required("Symptoms type is required."),
            chestPain: Yup.boolean().required("Chest pain status is required."),
            chestPainType: Yup.mixed()
              .oneOf(["Typical", "Atypical"], "Chest pain type must be Typical or Atypical.")
              .nullable(),
            shortnessOfBreath: Yup.boolean().required(
              "Shortness of breath status is required."
            ),
            shoulderOrArmPain: Yup.boolean().required(
              "Shoulder or arm pain status is required."
            ),
            jawPain: Yup.boolean().required("Jaw pain status is required."),
            dizzinessOrPresyncope: Yup.boolean().required(
              "Dizziness or presyncope status is required."
            ),
            nausea: Yup.boolean().required("Nausea status is required."),
          }
        : {}),
    }),

    [StepsEnum.CLINICAL_TWO]: Yup.object().shape({
      smokingStatus: Yup.mixed()
        .oneOf(
          [
            "No",
            "Cessation Over 5yrs",
            "Cessation Less than 5yrs",
            "Yes (Over a pack/day)",
            "Yes (Less than a pack/day)",
          ],
          "Invalid smoking status."
        )
        .required("Smoking status is required."),

      takingOtherDrugs: Yup.string().required(
        "Taking other drugs field is required."
      ),

      totalCholesterol: Yup.number()
        .positive("Total cholesterol must be a positive number.")
        .required("Total cholesterol is required."),

      ldl: Yup.number()
        .positive("LDL must be a positive number.")
        .required("LDL is required."),

      triglycerides: Yup.number()
        .positive("Triglycerides must be a positive number.")
        .required("Triglycerides are required."),

      bpSystolic: Yup.number()
        .integer("BP systolic must be an integer.")
        .required("BP systolic is required."),

      bpDiastolic: Yup.number()
        .integer("BP diastolic must be an integer.")
        .required("BP diastolic is required."),

      sugarLevel: Yup.number()
        .positive("Sugar level must be a positive number.")
        .required("Sugar level is required."),

      height: Yup.number()
        .positive("Height must be a positive number.")
        .required("Height is required."),

      weight: Yup.number()
        .positive("Weight must be a positive number.")
        .required("Weight is required."),

      notes: Yup.string().nullable(),
    }),

    [StepsEnum.CLINICAL_THREE]: Yup.object().shape({
      copd: Yup.boolean().required("COPD status is required."),

      arrhythmiasOrConductionAbnormality: Yup.boolean().required(
        "Arrhythmias or conduction abnormality status is required."
      ),

      peripheralArterialDisease: Yup.boolean().required(
        "Peripheral arterial disease status is required."
      ),

      familyHistoryCardiovascularEvents: Yup.boolean().required(
        "Family history of cardiovascular events status is required."
      ),

      cerebrovascularDisease: Yup.boolean().required(
        "Cerebrovascular disease status is required."
      ),

      strokeOrTiaOrRIND: Yup.boolean().required(
        "Stroke, TIA, or RIND status is required."
      ),

      chronicHeartFailure: Yup.boolean().required(
        "Chronic heart failure status is required."
      ),
    }),

    [StepsEnum.LAST_STEP]: Yup.object().shape({
      hemodynamicInstability: Yup.boolean().required(
        "Hemodynamic instability status is required."
      ),

      previousCardiacEvent: Yup.boolean().required(
        "Previous cardiac event status is required."
      ),
      otherCoMorbidities: Yup.string().nullable(),
      lvefLessThan30: Yup.boolean().required("LVEF <30% status is required."),

      pulmonaryEdema: Yup.boolean().required(
        "Pulmonary edema status is required."
      ),

      anemia: Yup.boolean().required("Anemia status is required."),
    }),
  };
};


export default getValidationSchemas;