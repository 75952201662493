import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface ExportSelectionProps {
  onSelect: (selectedOption: string) => void;
}

const ExportSelection: React.FC<ExportSelectionProps> = ({ onSelect }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    onSelect(event.target.value);
  };

  const { t } = useTranslation();

  return (
    <div className="export-selection">
      <div className="radio-group">
        <label>
          <input
            type="radio"
            name="exportType"
            value="JSON"
            onChange={handleOptionChange}
          />
          {t("general.buttons.json")}
        </label>
        <label>
          <input
            type="radio"
            name="exportType"
            value="Excel"
            onChange={handleOptionChange}
          />
          {t("general.buttons.excel")}
        </label>
      </div>
    </div>
  );
};

export default ExportSelection;
