import "./../styles/homePage/menu.scss";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menuIcons } from "../common/assats";
import { useTranslation } from "react-i18next";
import { Role } from "../common/consts/roles";
import Cookies from "js-cookie";
import Avatar from "../components/Avatar";
import { NavigationPaths } from "../common/consts/navigationPaths";
import { sysConfig } from "../config";
import { logoutRequest } from "../common/api/apiCalls";
import { useMenuStore } from "../common/store/menuToggle.store";
import { getFirstLettersOfFirstTwoWords } from "../common/function/generalFunctions";
import React from "react";
import useUserStore from "../common/store/user.store";

const userMenuAccess: Record<Role, string[]> = {
  ClinicalUser: [
    "patients",
    "about",
    "support",
    "contact",
    "settings",
    "logout",
  ],
  NonClinicalUser: [
    "patients",
    "about",
    "support",
    "contact",
    "settings",
    "logout",
  ],
  SiteAdmin: [
    "dashboard",
    "patients",
    "users",
    "devices",
    "about",
    "support",
    "contact",
    "settings",
    "logout",
  ],
  SuperAdmin: [
    "dashboard",
    "users",
    "sites",
    "devices",
    "fota",
    "about",
    "support",
    "contact",
    "settings",
    "logout",
  ],
};

const MenuSideBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showUserText, setShowUserText] = useState(true);
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
  const { isCollapsed, toggleMenuCollapsed, isMounted, toggleMenuMounted } =
    useMenuStore();

  const { setUser, getUser } = useUserStore();
  const user = useUserStore((state) => state.user);

  const hasAccess = (component: string): boolean => {
    return (
      user?.role?.some((role) => userMenuAccess[role]?.includes(component)) ??
      false
    );
  };

  useEffect(() => {
    getUser()
  }, []);
  

  const logout = async () => {
    setUser(undefined);
    Cookies.remove("access_token");
    await logoutRequest();
    navigate("/", { replace: true });
  };

  const renderMenuItem = (component: string, path: string, icon: string) => {
    if (!hasAccess(component)) return null;

    const clearedPath = path.split("/")[2];
    const isActive = location.pathname.includes(clearedPath);
    return (
      <Link className="link-style" to={path}>
        <div className="item">
          <div className="item-icon">
            <img src={icon} alt={`${component} icon`} />
          </div>
          {!isCollapsed && (
            <span className={`menu-text ${isActive ? "active-tab" : ""}`}>
              {t(`menu.${component}`)}
            </span>
          )}
        </div>
      </Link>
    );
  };

  const navigateToUserProfile = () => {
    navigate(NavigationPaths.USER_PROFILE);
  };

  const toggleCollapsed = () => {
    toggleMenuCollapsed();
    if (isCollapsed) {
      setShowUserText(false);
      setTimeout(() => setShowUserText(true), sysConfig.menuSetTimeUserText);
    } else {
      setShowUserText(false);
    }
  };

  return (
    <div
      className={`menu-side-bar-main-container ${
        isCollapsed ? "collapsed" : ""
      }`}
    >
      <div className="menu-headline">
        <h5 className="text">{t("menu.main")}</h5>
        <div
          onClick={() => {
            toggleMenuMounted();
            toggleCollapsed();
          }}
          className="flex-center collapsed-button"
        >
          <img
            className={isCollapsed ? "rotate" : "open"}
            src={menuIcons.arrow}
            alt="Toggle Menu"
          />
        </div>
      </div>
      {renderMenuItem(
        "dashboard",
        NavigationPaths.DASHBOARD,
        menuIcons.dashboard
      )}
      {renderMenuItem(
        "patients",
        NavigationPaths.PATIENTS,
        menuIcons.patientList
      )}
      {renderMenuItem("sites", NavigationPaths.SITES, menuIcons.sites)}
      {renderMenuItem("users", NavigationPaths.USERS, menuIcons.users)}
      {renderMenuItem("devices", NavigationPaths.DEVICES, menuIcons.devices)}
      {renderMenuItem("fota", NavigationPaths.FOTA, menuIcons.fota)}
      {renderMenuItem("about", NavigationPaths.ABOUT, menuIcons.about)}
      {renderMenuItem("support", NavigationPaths.SUPPORT, menuIcons.support)}
      {renderMenuItem("contact", NavigationPaths.CONTACT, menuIcons.contact)}
      {renderMenuItem("settings", NavigationPaths.SETTINGS, menuIcons.settings)}
      {hasAccess("logout") && (
        <Link onClick={logout} className="link-style" to={""}>
          <div className="item">
            <span>
              <img src={menuIcons.logout} alt="Logout Icon" />
            </span>
            {!isCollapsed && (
              <span className="menu-text">{t("menu.logout")}</span>
            )}
          </div>
        </Link>
      )}

      <div className="user-info-container" onClick={navigateToUserProfile}>
        <Avatar
          initials={getFirstLettersOfFirstTwoWords(
            user?.fullName || ""
          ).toUpperCase()}
        />
        {!isCollapsed && showUserText && (
          <div className={`user-text-container`}>
            <span className="full-name-text">
              {user?.fullName}{" "}
              <img
                className="arrow-icon"
                src={menuIcons.arrow}
                alt="Arrow Icon"
              />
            </span>
            <span className="user-name-text">{user?.username}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuSideBar;
