import { useEffect, useRef, useState } from "react";
import "../../../styles/homePage/addPatient.scss";
import AddPatientForm from "./AddPatientForm";
import {
  ClinicalInfoInterface,
  DemographicInfoInterface,
} from "../../../types/PatientData.interface";
import infoIcon from "../../../common/assats/icons/infoIcon.svg";
import addressIcon from "../../../common/assats/icons/addressIcon.svg";
import clinicalIcon from "../../../common/assats/icons/clinicalIcon.svg";
import {
  createPatient,
  createPatientClinical,
  updatePatient,
  updatePatientClinical,
} from "../../../common/api/apiCalls";
import patientDataTemplate from "./patientDataTemplate";
import DynamicModal from "../../common/Modal/DynamicModal";
import { ReactComponent as DisqualifiedIcon } from "../../../common/assats/icons/disqualifiedIcon.svg";
import getValidationSchemas, {
  StepsEnum,
} from "../../../common/validations/ValidationSchemas";
import useFormValidation from "../../../common/hooks/UseFormValidation";
import { useLocation, useNavigate } from "react-router-dom";
import MainHome from "../../MainHome";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../common/contexts/useToast";
import { NavigationPaths } from "../../../common/consts/navigationPaths";

const usePrevious = (
  value: ClinicalInfoInterface & DemographicInfoInterface
) => {
  const ref = useRef<
    (ClinicalInfoInterface & DemographicInfoInterface) | undefined
  >(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const AddPatient = () => {
  const location = useLocation();
  const [step, setStep] = useState<number>(StepsEnum.DEMOGRAPHICS);
  const [showErrors, setShowErrors] = useState(true);
  const [patientDisqualified, setPatientDisqualified] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const locationState = location.state || null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const [patientData, setPatientData] = useState<
    ClinicalInfoInterface & DemographicInfoInterface
  >({
    ...patientDataTemplate,
  });

  const { checkForErrors, errors, touched, setTouched } = useFormValidation({
    formValues: patientData,
    validationSchema: getValidationSchemas(patientData)[step as StepsEnum],
  });
  const previousPatientData = usePrevious(patientData);

  
  useEffect(() => {
    if (previousPatientData) {
      // Loop through the keys of patientData to detect which field changed
      const changedField = Object.keys(patientData).find(
        (key) =>
          patientData[
            key as keyof (ClinicalInfoInterface & DemographicInfoInterface)
          ] !==
          previousPatientData[
            key as keyof (ClinicalInfoInterface & DemographicInfoInterface)
          ]
      );

      if (changedField) {
        // Pass the changed field to checkForErrors
        checkForErrors?.({
          name: changedField as keyof ClinicalInfoInterface &
            DemographicInfoInterface,
          checkAll: false,
        });
      } else {
        checkForErrors?.({ checkAll: false });
      }
    }

    if(patientData.isSymptomatic){
      
    }
  }, [patientData]);

  useEffect(() => {
    if (locationState) {
      setIsEdit(true);
      setPatientData({ ...locationState });
    }
  }, [locationState]);

  const forwardStep = async () => {
    const test = await checkForErrors({ checkAll: true });
    if (test) {
      return;
    }
    setStep(step + 1);
  };

  const backStep = () => {
    setStep(step - 1);
  };

  const submitForm = async () => {
    if (await checkForErrors({ checkAll: true })) {
      return;
    }

    if (
      patientData.arrhythmiasOrConductionAbnormality ||
      patientData.strokeOrTiaOrRIND ||
      patientData.chronicHeartFailure ||
      patientData.lvefLessThan30 ||
      patientData.previousCardiacEvent ||
      patientData.hemodynamicInstability ||
      patientData.pulmonaryEdema ||
      patientData.anemia
    ) {
      setPatientDisqualified(true);
      return;
    }

    const patientDataObj = {
      patientId: locationState?.patientId || undefined,
      honorific: patientData.honorific || undefined,
      firstName: patientData.firstName,
      middleName: patientData.middleName,
      identityNumber: patientData.identityNumber,
      lastName: patientData.lastName,
      birthday: patientData.birthday,
      gender: patientData.gender,
      phoneNumber: patientData.phoneNumber,
      email: patientData.email,
      state: patientData.state,
      address: patientData.address,
      city: patientData.city,
      country: patientData.country,
      zip: patientData.zip,
      maritalStatus: patientData.maritalStatus,
      race: patientData.race,
      medId: patientData?.medId,
    };

    let createPatientResponse = null;
    if (isEdit) {
      createPatientResponse = await updatePatient(
        patientDataObj,
        locationState.identityNumber
      );
    } else {
      createPatientResponse = await createPatient(patientDataObj);
    }

    if (!createPatientResponse?.success) {
      showToast("error", "Error", t(createPatientResponse.data.errorMessage));
      return;
    }

    const clinicalInfoData = {
      patientId:
        locationState?.patientId || createPatientResponse?.data?.patient?.id,
      height: parseFloat(patientData?.height || "") || undefined,
      weight: parseFloat(patientData.weight || "") || undefined,
      totalCholesterol:
        parseFloat(patientData.totalCholesterol || "") || undefined,
      ldl: parseFloat(patientData.ldl || "") || undefined,
      triglycerides: parseFloat(patientData.triglycerides || "") || undefined,
      bpSystolic: parseInt(patientData.bpSystolic || "", 10) || undefined,
      bpDiastolic: parseInt(patientData.bpDiastolic || "", 10) || undefined,
      sugarLevel: parseFloat(patientData.sugarLevel || "") || undefined,
      isSymptomatic: patientData.isSymptomatic,
      symptomsType: patientData?.symptomsType || undefined,
      chestPain: patientData?.chestPain,
      chestPainType: patientData?.chestPainType || undefined,
      shortnessOfBreath: patientData?.shortnessOfBreath,
      shoulderOrArmPain: patientData?.shoulderOrArmPain,
      jawPain: patientData?.jawPain,
      dizzinessOrPresyncope: patientData?.dizzinessOrPresyncope,
      nausea: patientData?.nausea,
      smokingStatus: patientData?.smokingStatus || undefined,
      takingOtherDrugs: patientData?.takingOtherDrugs || undefined,
      copd: patientData?.copd,
      arrhythmiasOrConductionAbnormality:
        patientData?.arrhythmiasOrConductionAbnormality,
      peripheralArterialDisease: patientData?.peripheralArterialDisease,
      familyHistoryCardiovascularEvents:
        patientData?.familyHistoryCardiovascularEvents,
      cerebrovascularDisease: patientData?.cerebrovascularDisease,
      strokeOrTiaOrRIND: patientData?.strokeOrTiaOrRIND,
      chronicHeartFailure: patientData?.chronicHeartFailure,
      lvefLessThan30: patientData?.lvefLessThan30,
      previousCardiacEvent: patientData?.previousCardiacEvent,
      hemodynamicInstability: patientData?.hemodynamicInstability,
      pulmonaryEdema: patientData?.pulmonaryEdema,
      anemia: patientData?.anemia,
      notes: patientData?.notes || undefined,
      otherCoMorbidities: patientData?.otherCoMorbidities || undefined,
    };
    let patientClinicalInfo = null;
    if (isEdit) {
      patientClinicalInfo = await updatePatientClinical(
        { ...clinicalInfoData },
        locationState.patientId
      );
    } else {
      patientClinicalInfo = await createPatientClinical(clinicalInfoData);
    }
    if (!patientClinicalInfo?.success) {
      showToast("error", "Error", t(patientClinicalInfo.data.errorMessage));
      return;
    }

    showToast(
      "success",
      "Success",
      isEdit
        ? t("patientsList.patientUpdateSuccess")
        : t("patientsList.patientCreateSuccess")
    );
    handleClose();
  };

  const renderStepIcon = () => {
    switch (step) {
      case StepsEnum.DEMOGRAPHICS:
        return infoIcon;
      case StepsEnum.ADDRESS:
        return addressIcon;
      default:
        return clinicalIcon;
    }
  };

  const handleClose = () => {
    navigate(NavigationPaths.PATIENTS);
  };

  const renderStepText = () => {
    switch (step) {
      case StepsEnum.DEMOGRAPHICS:
        return t("patientsList.personalInformation.title");
      case StepsEnum.ADDRESS:
        return t("patientsList.address.title");
      case StepsEnum.CLINICAL_ONE:
      case StepsEnum.CLINICAL_TWO:
      case StepsEnum.CLINICAL_THREE:
        return t("patientsList.clinicalInformation.title");
      default:
        return t("patientsList.demographicInformation.title");
    }
  };

  return (
    <MainHome>
      <div className="add-patient-container">
        <div className="add-patient-inner-container">
          <div className="add-patient-header">
            {isEdit
              ? t("patientsList.editPatient")
              : t("patientsList.addNewPatient")}
          </div>
          <div className="custom-divider" />
          <div className="add-patient-stage-title">
            <div className="stage-icon">
              <img src={renderStepIcon()} alt="Step Icon" />
            </div>
            <div className="stage-title">{renderStepText()}</div>
          </div>
          <AddPatientForm
            showErrors={showErrors}
            errors={errors}
            checkForErrors={({ checkAll, name }) =>
              checkForErrors({ checkAll, name })
            }
            currentStep={step}
            setPatientData={setPatientData}
            patientData={patientData}
            touched={touched}
            setTouched={setTouched}
          />
        </div>
        <hr />
        <div className="add-patient-button-group">
          {step < 1 ? (
            <button
              className="small secondary-button"
              onClick={() => {
                handleClose();
              }}
            >
              {t("general.buttons.cancel")}
            </button>
          ) : (
            <button className="secondary-button" onClick={backStep}>
              {t("general.buttons.back")}
            </button>
          )}

          <button
            className="primary-button large"
            onClick={step === StepsEnum.LAST_STEP ? submitForm : forwardStep}
          >
            {step === StepsEnum.LAST_STEP
              ? t("general.buttons.finish")
              : t("general.buttons.next")}
          </button>
        </div>
        {patientDisqualified && (
          <DynamicModal
            title={"Warning"}
            icon={<DisqualifiedIcon />}
            subtext={"CORA exam cannot be conducted on this patient"}
            buttonText={"OK"}
            onButtonClick={() => setPatientDisqualified(false)}
            onClose={() => setPatientDisqualified(false)}
          />
        )}
      </div>
    </MainHome>
  );
};

export default AddPatient;
