import React, { useEffect, useState } from "react";
import MainHome from "../../components/MainHome";
import { useTranslation } from "react-i18next";
import { useToast } from "../../common/contexts/useToast";
import useUserStore from "../../common/store/user.store";
import { sysConfig } from "../../config";
import CustomDropdown from "../../components/common/inputs/CustomDropdown";
import "../../styles/components/SettingsPage.scss";
import CustomChipInput from "../../components/common/inputs/CustomChipInput";
import { updateUserSettings } from "../../common/api/apiCalls";

const Settings = () => {
  const { user } = useUserStore();
  const { t } = useTranslation();

  const [systemLanguage, setSystemLanguage] = useState<string>("English");

  const languageArr = sysConfig.LANGUAGE_OPTIONS.map((option) => option.label);

  const submitPredefinedEmails = async (emails: string[]) => {
    await updateUserSettings({ systemLanguage, predefinedEmails:emails });
  };

  return (
    <MainHome>
      <div className="settings-container">
        <div className="header-section pt-5 pb-3">
          <h2 className="fw-bold">{t("menu.settings")}</h2>
        </div>
        <hr />
        <div className="language-section mb-4">
          <h3 className="mt-3">{t("settings.siteLanguage")}</h3>
          <span>{t("settings.siteLanguageDescription")}</span>
          <div className="input-container mt-3 mb-3">
            <CustomDropdown
              name={"language"}
              label={""}
              optionsArray={languageArr}
              value={systemLanguage}
              handleInputChange={(e: any) => setSystemLanguage(e.target.value)}
            />
          </div>
        </div>
        <hr />
        <div className="email-section">
          <h3 className="mt-3">{t("settings.emailList")}</h3>
          <span>{t("settings.emailListDescription")}</span>
          <CustomChipInput
            placeHolder="general.placeholders.emailPlaceHolder"
            validationRegex={sysConfig.EMAIL_PATTERN}
            ArrOfChips={user?.predefinedEmails || []}
            onSubmit={submitPredefinedEmails}
            customError="general.errors.emailChipsInvalid"
          />
        </div>
      </div>
    </MainHome>
  );
};

export default Settings;
