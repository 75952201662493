import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../Button";
import "../../../../styles/components/signModal.scss";

interface SignModalProps {
  show: boolean;
  onClose: () => void;
  onSignComplete: (signature: string) => void;
}

const SignModal: React.FC<SignModalProps> = ({
  show,
  onClose,
  onSignComplete,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { t } = useTranslation();
  const [isDrawing, setIsDrawing] = useState(false);

  const handleClear = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const signatureDataUrl = canvas.toDataURL("image/png");
      onSignComplete(signatureDataUrl);
      onClose();
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.lineWidth = 2;
        ctx.lineCap = "round";
        ctx.strokeStyle = "black"; // Drawing color
      }
    }
  }, []);

  const startDrawing = (e: React.MouseEvent) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.beginPath();
        const rect = canvas.getBoundingClientRect();
        ctx.moveTo(e.clientX - rect.left, e.clientY - rect.top);
        setIsDrawing(true);
      }
    }
  };

  const draw = (e: React.MouseEvent) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const rect = canvas.getBoundingClientRect();
        ctx.lineTo(e.clientX - rect.left, e.clientY - rect.top);
        ctx.stroke();
      }
    }
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  return (
    <Modal show={show} onHide={onClose} centered className="custom-sign-modal">
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>Sign Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <canvas
          ref={canvasRef}
          width={425}
          height={150}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        />
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <ButtonCustom
          type="button"
          onClick={handleClear}
          className="btn-primary btn-lg"
          context={t("general.buttons.clear")}
        />
        <ButtonCustom
          type="button"
          onClick={handleSave}
          className="btn-primary btn-lg"
          context={t("general.buttons.save")}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SignModal;
