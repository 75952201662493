import { Col, Form, Button } from "react-bootstrap";
import "../../styles/loginStyles/personalInfo.scss";

import { useNavigate } from "react-router-dom";
import HalfScreenLogo from "../../components/HalfScreenLogo";
import i18n from "../../i18n/i18n";
import {
  getDataForPersonalInfo,
  postPersonalInfo,
} from "../../common/api/apiCalls";
import { useEffect, useState } from "react";
import { Positions } from "../../components/common/general/positions";
import { useToast } from "../../common/contexts/useToast";
import { sysConfig } from "../../config";
import { t } from "i18next";

const positionOptions: { text: string; value: string }[] = Positions.map(
  (e) => ({ text: i18n.t(e), value: e })
);
const PersonalInfo = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [userData, setUserData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    position: "",
    phoneNumber: "",
  });
  const SIGN_IN_TEXT_MAIN_TITLE = i18n.t(
    "personal_info.SIGN_IN_TEXT_MAIN_TITLE"
  );
  const SIGN_IN_TEXT_SUB_TITLE = i18n.t("personal_info.SIGN_IN_TEXT_SUB_TITLE");
  const SIGN_IN_TEXT_INPUT_1_PLACEHOLDER = i18n.t(
    "personal_info.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_INPUT_2_PLACEHOLDER = i18n.t(
    "personal_info.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_INPUT_3_PLACEHOLDER = i18n.t(
    "personal_info.SIGN_IN_TEXT_INPUT_3_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_INPUT_4_PLACEHOLDER = i18n.t(
    "personal_info.SIGN_IN_TEXT_INPUT_4_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_INPUT_5_PLACEHOLDER = i18n.t(
    "personal_info.SIGN_IN_TEXT_INPUT_5_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_BUTTON_1 = i18n.t("personal_info.SIGN_IN_TEXT_BUTTON_1");
  const SIGN_IN_TEXT_BUTTON_2 = i18n.t("personal_info.SIGN_IN_TEXT_BUTTON_2");
  const SIGN_IN_TEXT_LABEL_1 = i18n.t("personal_info.SIGN_IN_TEXT_LABEL_1");

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (!sysConfig.PHONE_NUMBER_PATTERN.test(userData.phoneNumber)) {
      showToast("error", "Error", i18n.t("general.errors.phoneNumberError"));
      return;
    }

    const res = await postPersonalInfo({
      firstName: userData.firstName,
      middleName: userData.middleName,
      lastName: userData.lastName,
      position: userData.position,
      phoneNumber: userData.phoneNumber,
    });
    if (res.success) {
      navigate("/Success/registration");
    } else {
      showToast(
        "error",
        "Error",
        res.data?.errorMessage || i18n.t("general.errors.generalError")
      );
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const res = await getDataForPersonalInfo();
    if (res?.success) {
      setUserData({
        firstName: res.data.firstName || "",
        middleName: res.data.middleName || "",
        lastName: res.data.lastName || "",
        position: res.data.position || "",
        phoneNumber: res.data.phoneNumber || "",
      });
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <HalfScreenLogo>
      <Col className="right-part d-flex align-items-center justify-content-center">
        <Form className="form-container" onSubmit={onSubmit}>
          <Form.Group
            className="title-group"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Label className="global-big-label text-1o">
              {SIGN_IN_TEXT_MAIN_TITLE}
            </Form.Label>
            <Form.Label className="global-sub-label text-3o">
              {SIGN_IN_TEXT_SUB_TITLE}
            </Form.Label>
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Control
              className="global-input"
              type="text"
              name="firstName"
              required
              value={userData.firstName}
              onChange={handleInputChange}
              placeholder={SIGN_IN_TEXT_INPUT_1_PLACEHOLDER}
            />
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Control
              className="global-input"
              type="text"
              name="middleName"
              value={userData.middleName}
              onChange={handleInputChange}
              placeholder={SIGN_IN_TEXT_INPUT_2_PLACEHOLDER}
            />
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Control
              className="global-input"
              type="text"
              name="lastName"
              required
              value={userData.lastName}
              onChange={handleInputChange}
              placeholder={SIGN_IN_TEXT_INPUT_3_PLACEHOLDER}
            />
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Select
              size="lg"
              className="global-input"
              name="position"
              onChange={handleInputChange}
              value={userData.position}
            >
              <option value="" disabled>
                {i18n.t("general.positions.placeholder")}
              </option>
              {positionOptions.map((val, index) => (
                <option key={index} value={t(val.value)}>
                  {t(val.text)}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Control
              className="global-input"
              type="phone"
              name="phoneNumber"
              required
              value={userData.phoneNumber}
              onChange={handleInputChange}
              placeholder={SIGN_IN_TEXT_INPUT_5_PLACEHOLDER}
            />
          </Form.Group>

          <Button
            className="global-button group-spacing"
            variant="primary"
            type="submit"
          >
            {SIGN_IN_TEXT_BUTTON_1}
          </Button>
        </Form>
      </Col>
    </HalfScreenLogo>
  );
};

export default PersonalInfo;
