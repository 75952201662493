// userStore.ts
import { create } from "zustand";
import { User } from "../consts/user";
import { getUserInfo } from "../api/apiCalls";

interface UserStore {
  user: User | null | undefined;
  setUser: (user: User | null | undefined) => void;
  getUser: () => Promise<User | null | undefined>;
  updateUser: (newUserData: Partial<User>) => void;
}

const useUserStore = create<UserStore>((set, get) => ({
  user: null,
  
  setUser: (user) => set({ user }),

  getUser: async () => {
    const u = await getUserInfo();
    set({ user: u });
    return u;
  },

  updateUser: (newUserData) => {
    set((state) => {
      const prevUser = state.user;
      if (!prevUser) return state;

      return {
        user: {
          ...prevUser,
          ...newUserData,
        },
      };
    });
  },
}));

export default useUserStore;
