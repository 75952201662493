import React, { useEffect, useRef } from "react";
import ButtonCustom from "../common/Button";
import { useTranslation } from "react-i18next";
import SignModal from "../common/general/signModal/signModal";

interface PatientExamProps {
  htmlContent: string;
  backClick?: () => void;
  singReport?: (base64Image:string) => void;
}

const PatientExamPDFViewAndSign: React.FC<PatientExamProps> = ({
  htmlContent,
  backClick,
  singReport,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const iframeDoc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow?.document;
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(htmlContent);
        iframeDoc.close();
      }
    }
  }, [htmlContent]);

  const onCloseHandler = () => {
    setShowModal(false);
  };

  const singHandler = () => {
    setShowModal(true);
  }

  return (
    <div>
      <SignModal
        show={showModal}
        onClose={() => {
          onCloseHandler();
        }}
        onSignComplete={(signature: string) => {
          singReport && singReport(signature);
        }}
      />
      <iframe
        ref={iframeRef}
        title="Patient Exam"
        style={{
          width: "100%",
          height: "650px",
          border: "1px solid #ccc",
          marginBottom: "20px",
        }}
      />
      <div className="actions d-flex justify-content-between align-items-center me-3 ms-3">
        <div className="align-self-start">
          <ButtonCustom
            type="button"
            onClick={backClick}
            className="btn-primary btn-lg"
            context={t("general.buttons.back")}
          />
        </div>
        <ButtonCustom
          type="button"
          onClick={singHandler}
          className="btn-primary btn-lg"
          context={t("general.buttons.sign")}
        />
      </div>
    </div>
  );
};

export default PatientExamPDFViewAndSign;
