import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "../../common/contexts/useToast";
import { useLocation, useNavigate } from "react-router-dom";
import { UserCreateInterface } from "../../common/interfaces/usersData.interface";
import * as Yup from "yup";
import MainHome from "../MainHome";
import { ReactComponent as InfoIcon } from "../../common/assats/icons/infoIcon.svg";
import ButtonCustom from "../common/Button";
import FormBuilder, { FormBuilderInterface } from "../common/FormBuilder";
import {
  createUser,
  getUserDataForCreation,
  updateUser,
} from "../../common/api/apiCalls";
import { useQuery } from "@tanstack/react-query";
import { Positions } from "../common/general/positions";
import userCreationValidation from "../../common/validations/UsersCreateValidationSchemas";
import {
  getRoleDisplayNames,
  getRolesByRoleName,
} from "../../common/function/generalFunctions";
import useUserStore from "../../common/store/user.store";

const userInformationCreateConfig: FormBuilderInterface[] = [
  {
    name: "honorific",
    label: "general.honorific.title",
    type: "dropdown",
    optionsArray: [
      "general.honorific.mr",
      "general.honorific.mrs",
      "general.honorific.ms",
      "general.honorific.dr",
    ],
    required: false,
    translateOffset: false,
    translateLabel: true,
    translateOptions: true,
  },
  {
    name: "firstName",
    label: "usersList.usersListCreate.firstName",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "middleName",
    label: "usersList.usersListCreate.middleName",
    type: "text",
    required: false,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "lastName",
    label: "usersList.usersListCreate.lastName",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "email",
    label: "usersList.usersListCreate.userEmail",
    type: "text",
    required: true,
    isDisabled: false,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "phoneNumber",
    label: "usersList.usersListCreate.userPhone",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "relatedSite",
    label: "usersList.usersListCreate.relatedSite",
    type: "dropdown",
    optionsArray: [],
    required: false,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "position",
    label: "usersList.usersListCreate.userPosition",
    type: "dropdown",
    optionsArray: Positions,
    required: false,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "role",
    label: "usersList.usersListCreate.userRole",
    type: "dropdown",
    optionsArray: [],
    required: false,
    translateOffset: false,
    translateLabel: true,
  },
];

const UserCreatePage = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const locationUserDataState = location.state || null;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { user } = useUserStore();

  const [userData, setUserData] = useState<UserCreateInterface>({
    honorific: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    relatedSite: "",
    position: "",
    role: "",
  });

  const {
    data: response,
    isPending,
    error,
  } = useQuery({
    queryKey: [],
    queryFn: () => getUserDataForCreation(),
  });

  useEffect(() => {
    if (!locationUserDataState || !locationUserDataState?.username) {
      return;
    } else if (!!locationUserDataState) {
      setIsEdit(true);
    }
    setUserData({
      firstName: locationUserDataState?.tableProps?.firstName || "",
      middleName: locationUserDataState?.tableProps?.middleName || "",
      lastName: locationUserDataState?.tableProps?.lastName || "",
      email: locationUserDataState?.username || "",
      phoneNumber: locationUserDataState?.phoneNumber || "",
      relatedSite: locationUserDataState?.tableProps?.sites?.[0]?.siteName,
      position: locationUserDataState?.position,
      role: locationUserDataState.tableProps?.roles?.[0]?.name && getRoleDisplayNames(false,locationUserDataState.tableProps?.roles?.[0]?.name),
      honorific: locationUserDataState?.tableProps?.honorific || "",
    });
  }, [locationUserDataState]);

  const navigateToUsersList = () => {
    navigate("/Home/Users", { replace: true });
    return;
  };

  const handleInputChange = (name: string, value: any) => {
    setUserData({ ...userData, [name]: value });
  };

  const updateConfigData = () => {
    if (
      !response?.data.roles?.length ||
      !response?.data.sites?.length ||
      !response?.success
    ) {
      return;
    }

    userInformationCreateConfig.forEach((field) => {
      if (field.name === "role") {
        const userRole = user?.role?.[0] || "";
        const arrRoles = getRolesByRoleName(userRole);
        field.optionsArray = arrRoles as string[];
      }
      if (field.name === "relatedSite") {
        field.optionsArray = response.data.sites;
      }
      if (
        (isEdit || locationUserDataState !== null) &&
        field.name === "email"
      ) {
        field.isDisabled = true;
      } else {
        field.isDisabled = false;
      }
      if (field.name === "position") {
        field.optionsArray = Positions.map((position) => t(position));
      }
    });
  };

  updateConfigData();

  const handleSubmit = async () => {
    try {
      await userCreationValidation.validate(userData, { abortEarly: false });

      const data: UserCreateInterface = {
        honorific: userData.honorific,
        firstName: userData.firstName,
        middleName: userData.middleName,
        lastName: userData.lastName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        relatedSite: userData.relatedSite,
        position: userData.position,
        role: userData.role,
      };
      let res = null;

      if (isEdit) {
        res = await updateUser(data);
      } else {
        res = await createUser(data);
      }
      if (res.success) {
        showToast(
          "success",
          "Success",
          isEdit
            ? t("usersList.usersListCreate.updateSuccess")
            : t("usersList.usersListCreate.addSuccess")
        );
        navigateToUsersList();
      } else {
        showToast("error", "Error", t(res.data.errorMessage));
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: { [key: string]: string } = {};
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };

  return (
    <MainHome>
      <div className="page-container w-100 h-100 p-5 border rounded background-primary-white">
        <div className="container pr-5 pl-5 h-100 d-flex flex-column">
          <div className="mb-4">
            <h3 className="fw-bold">
              {isEdit
                ? t("usersList.usersListCreate.editUser")
                : t("usersList.usersListCreate.addNewUser")}
            </h3>
            <hr className="my-4" />
            <div className="ms-2 d-flex align-items-end">
              <InfoIcon />
              <h4 className="ms-2">
                {t("usersList.usersListCreate.userInformation")}
              </h4>
            </div>
          </div>
          <div className="flex-grow-1">
            <FormBuilder
              fields={userInformationCreateConfig}
              handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
              values={userData}
              showErrors={true}
              errors={errors}
              translateErrors={true}
            />
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end gap-2">
            <ButtonCustom
              type="button"
              onClick={navigateToUsersList}
              className="btn-secondary btn-lg"
              context={t("general.buttons.cancel")}
            />
            <ButtonCustom
              type="button"
              onClick={handleSubmit}
              className=" btn-primary btn-lg"
              context={
                isEdit
                  ? t("general.buttons.save")
                  : t("usersList.usersListCreate.saveUser")
              }
            />
          </div>
        </div>
      </div>
    </MainHome>
  );
};

export default UserCreatePage;
