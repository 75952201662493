import { Col, Form, Button, Row } from "react-bootstrap";
import "../styles/loginStyles/success.scss";

import {useNavigate, useParams } from "react-router-dom";
import HalfScreenLogo from "./HalfScreenLogo";
import { useTranslation } from "react-i18next";

const Success = () => {
  const { type } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = (event: any) => {
    event.preventDefault();


    if (type === "registration" || type === "reset") {
      navigate("/", { replace: true });
    } else {
      navigate("/Register/PersonalInfo", { replace: true });
    }
  };

  function getText(str: string) {
    return t(`Success.${type}.${str}`);
  }
  return (
    <HalfScreenLogo>
      <Col className="right-part d-flex align-items-center justify-content-center">
        <Form className=" form-container" onSubmit={onSubmit}>
          <Form.Group
            className="title-group"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Label className="text-center global-big-label text-1o">
              {getText("SIGN_IN_TEXT_MAIN_TITLE")}
            </Form.Label>

            {type === "registration" ? (
              <Row className="flex-center-column">
                <Form.Label className="text-center global-sub-label text-3o">
                  {getText("SIGN_IN_TEXT_SUB_TITLE_1")}
                </Form.Label>
                <Form.Label className="text-center global-sub-label text-3o">
                  {getText("SIGN_IN_TEXT_SUB_TITLE_2")}
                </Form.Label>
              </Row>
            ) : (
              <Form.Label className="text-center global-big-label text-1o">
                {getText("SIGN_IN_TEXT_MAIN_TITLE_1")}
              </Form.Label>
            )}
          </Form.Group>

          <Button
            className="global-button group-spacing"
            variant="primary"
            type="submit"
          >
            {getText("SIGN_IN_TEXT_BUTTON_1")}
          </Button>
        </Form>
      </Col>
    </HalfScreenLogo>
  );
};

export default Success;
