import React, { FC, useState } from "react";
import "../../styles/homePage/table/sortTable.scss";
import { Form } from "react-bootstrap";
import { ReactComponent as SortArrow } from "../../common/assats/table/sortArrow.svg";
import { useTranslation } from "react-i18next";

export interface SortTableProps {
  sortKey: string;
  setSortKey: (val: string) => void;
  sortKeysArray: { name: string; val: string }[];
  setSortOrder: (str: "asc" | "desc") => void;
  sortOrder: "asc" | "desc";
  handleCheckAll?: (e: boolean) => void;
  filtersText?: string;
  isFetchingMore?: boolean;
  hasMore?: boolean;
  fetchMore?: () => void;
}
const SortTable: FC<SortTableProps> = ({
  sortOrder,
  setSortOrder,
  setSortKey,
  sortKey,
  sortKeysArray,
  handleCheckAll,
}) => {
  const { t } = useTranslation();

  function handleSort(str: string) {
    if (str !== sortKey || sortOrder === "desc") setSortOrder("asc");
    else setSortOrder("desc");

    setSortKey(str);
  }

  const handleCheckAllHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    const selected = e.currentTarget.checked;
    handleCheckAll && handleCheckAll(selected);
  };

  return (
    <div className="sort-table-main-container">
      <Form.Check
        inline
        onClick={(e) => {handleCheckAllHandler(e)}}
      />
      {sortKeysArray.map((val) => (
        <div
          key={val.name + Date.now()}
          className={"sort-cell "}
          onClick={() => handleSort(val.val)}
        >
          <span className={sortKey === val.val ? "bold-text" : ""}>
            {val.name}
          </span>
          <SortArrow
            className={`sort-icon ${
              sortOrder === "desc" && sortKey === val.val ? "rotate" : ""
            }`}
          />
        </div>
      ))}
      <div className="cell"></div>
    </div>
  );
};

export default SortTable;
