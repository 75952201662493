import React, { FC } from 'react';
import "../styles/homePage/mainHome.scss"
interface MainHomeProps {
    children: React.ReactNode;
}
const MainHome: FC<MainHomeProps> = ({ children }) => {
    return (
        <div className='content position-relative '>
            {children}
        </div>

    )
}

export default MainHome