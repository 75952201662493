import React, { useEffect } from "react";
import { Toast } from "react-bootstrap";
import { ToastContainerProps } from "../../../types/Toast.interface";
import "../../../styles/components/Toast.scss";
import { ReactComponent as SuccessIcon } from "../../../common/assats/toast/success.svg";
import { ReactComponent as InformationIcon } from "../../../common/assats/toast/information.svg";
import { ReactComponent as ErrorIcon } from "../../../common/assats/toast/error.svg";
import { ReactComponent as WarningIcon } from "../../../common/assats/toast/warning.svg";

const ToastContainer: React.FC<ToastContainerProps> = ({
  toasts,
  removeToast,
}) => {
  useEffect(() => {
    const timers = toasts.map((toast) =>
      setTimeout(() => {
        removeToast(toast.id);
      }, toast.duration || 5000) 
    );

    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [toasts, removeToast]);

  const renderIcons = (type: string) => {
    switch (type) {
      case "success":
        return <SuccessIcon />;
      case "info":
        return <InformationIcon />;
      case "error":
        return <ErrorIcon />;
      case "warning":
        return <WarningIcon />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className="toast-container position-fixed top-0 end-0 p-3"
      style={{ zIndex: 1055 }}
    >
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`toast show d-flex align-items-center toast-${
            toast.type || "info"
          }  toast-custom-container`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-icon ms-2">
            {renderIcons(toast.type || "info")}
          </div>

          {/* Make sure the container is flex and wide enough */}
          <div className="d-flex w-100 align-items-center">
            <div className="toast-body ms-1">
              <strong>{toast.title}</strong>
              <div>{toast.message}</div>
            </div>
            <button
              type="button"
              className="btn-close ms-auto me-2"
              aria-label="Close"
              onClick={() => removeToast(toast.id)}
            ></button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToastContainer;
