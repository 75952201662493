import axios, { AxiosError } from 'axios';

import { makeExportRequest, makeGetRequest, makePostRequest, makePostRequestForDownload, urlsPath } from "./restServices"
import { CreatePatientClinicalInterface, CreatePatientInterface, FirstLoginBodyType, PostLoginType, PostOTPType, PostPersonalInfoType, PostSetNewPasswordType } from "./apiBodyTypes"
import { getErrorMessage } from './apiErrorHandler';
import { BaseFetchOptions } from '../types/fetch.type';
import { UserCreateInterface, UserData } from '../interfaces/usersData.interface';
import { SiteProps, SitePropsWithOptionalEmail } from '../interfaces/Sites.interface';
import { DevicesCreateInterface } from '../interfaces/Devices.interface';

// let otpToken: string | null
let token: string | undefined

async function postLogin(body: PostLoginType): Promise<{ success: boolean, data?: { access_token: string, userData: UserData, status: string | undefined }, errorMessage?: string }> {
    try {
        const res = await makePostRequest("/users/Login", body)
        if (!res.data.success)
            throw new Error(res.data.error)
        return {
            success: true, data: {
                access_token: res.data.data.access_token,
                userData: res.data.data.userData,
                status: res.data.data?.status
            }
        }

    } catch (err) {
        return { success: false, errorMessage: getErrorMessage(err).message };
    }
}


async function postPersonalInfo(body: PostPersonalInfoType) {
    try {

        const res = await makePostRequest("/user-info/set-user-info", body)
        if (res.data.success)
            token = res.data.data.access_token
        return { success: true, data: {} }

    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function postSetFirstPassword(body: PostSetNewPasswordType): Promise<{ success: boolean, data?: { errorMessage?: { message: string, redirectPath: string } } }> {
    try {
        await makePostRequest("/users/first-login-change-password", body)
        return { success: true, data: {} }
    } catch (err) {
        const errorMessage = getErrorMessage(err);
        return { success: false, data: { errorMessage: { ...errorMessage, redirectPath: errorMessage.redirectPath || '' } } }
    }
}

async function getUserInfo() {
    try {
        const user = await makeGetRequest("users/get-user-by-token")
        return user.data
    }
    catch (err) {
        return { success: false, data: {} }
    }
}

async function getDataForPersonalInfo() {
    try {
        const data = await makeGetRequest("users/get-user-info")
        return { success: true, data: data.data }
    }
    catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function getUserByEmailForSite(userName: string) {
    try {
        const user = await makeGetRequest(`users/get-user-site`, { userName });
        return user.data
    }
    catch (err) {
        return { success: false, data: {} }
    }
}

async function getAllBranchPatients(params: BaseFetchOptions) {
    try {
        const patients = await makeGetRequest(`patients/get-all-site-patients`, params)
        if (patients.data.success)
            return patients.data.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function getAllBranchPatientsWithClinical(filters: { [key: string]: Array<string> }) {
    try {
        const patients = await makePostRequest(`patients/all-site-patients-with-clinical`, { filters })
        return { success: true, data: patients.data.data }
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}


async function getBranchPatientCounts() {
    try {
        const patients = await makePostRequest(`patients/get-all-site-patients`, { count: true })
        if (patients.data.success)
            return patients.data.data
    } catch (err) {
        return { success: false, data: {} }
    }
}

async function getPatient(identityNumber: string) {
    try {
        const patients = await makeGetRequest(`patients/get-patient/${identityNumber}`);
        if (patients.data)
            return patients.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function deletePatients(ids: string[]) {
    try {
        const patients = await makePostRequest(`patients/delete`, { idsToDelete: ids });
        if (patients.data)
            return patients.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function createPatient(body: CreatePatientInterface) {
    try {
        const patient = await makePostRequest(`patients/create-patients`, body);
        if (patient.data)
            return patient.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function updatePatient(body: CreatePatientInterface, patientId: string) {
    try {
        const patient = await makePostRequest(`patients/update/${patientId}`, body);
        if (patient.data)
            return patient.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }

}

async function updatePatientClinical(body: CreatePatientClinicalInterface, patientId: string) {
    try {
        const patient = await makePostRequest(`clinical-info/patient/update/${patientId}`, body);
        if (patient.data)
            return patient.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function createPatientClinical(body: CreatePatientClinicalInterface) {
    try {
        const patient = await makePostRequest(`clinical-info/patient`, body);
        if (patient.data)
            return patient.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}


async function getPatientClinical(patientId: string) {
    try {
        const clinicalInfo = await makeGetRequest(`clinical-info/patient/${patientId}`);
        if (clinicalInfo.data)
            return clinicalInfo.data;
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}


async function getSitesData(
    params: BaseFetchOptions,
) {
    try {
        const response = await makeGetRequest(`site/get-all`, params);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} }; //TODO add error handling? some tooltip?
        }
    } catch (error) {
        return { success: false, data: {} };
    }
};

async function createNewSite(body: SiteProps) {
    try {
        const response = await makePostRequest(`site/create`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function updateSite(body: SitePropsWithOptionalEmail) {
    try {
        const response = await makePostRequest(`site/update`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function deleteSite(ids: string[]) {
    try {
        const response = await makePostRequest(`site/delete`, { idsToDelete: ids });
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function getUsersData(params: BaseFetchOptions) {
    try {
        const response = await makeGetRequest(`users/get-users`, params);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function createUser(body: UserCreateInterface) {
    try {
        const response = await makePostRequest(`users/create-user`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function getUserDataForCreation() {
    try {
        const response = await makeGetRequest(`users/user-creation-data`);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function updateUser(body: UserCreateInterface) {
    try {
        const response = await makePostRequest(`users/update-user`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function deleteUsers(ids: string[]) {
    try {
        const response = await makePostRequest(`users/delete-users`, { idsToDelete: ids });
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function getDevicesList(params: BaseFetchOptions) {
    try {
        const response = await makeGetRequest(`devices/get-devices`, params);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function getDevicesDataForCreation() {
    try {
        const response = await makeGetRequest(`devices/device-creation-data`);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function createDevice(body: DevicesCreateInterface) {
    try {
        const response = await makePostRequest(`devices/create-device`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function updateDevice(body: DevicesCreateInterface) {
    try {
        const response = await makePostRequest(`devices/update-device`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function deleteDevice(ids: string[]) {
    try {
        const response = await makePostRequest(`devices/delete-devices`, { idsToDelete: ids });
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function logoutRequest() {
    try {
        await makePostRequest(`users/logout`, {});
        return { success: true, data: {} };
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function exportData(path: string, params: any) {
    try {
        const res = await makeExportRequest(path, params);
        return { success: true, data: res };
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function forgetPassword(email: string) {
    try {
        const res = await makePostRequest(urlsPath + "/users/forgot-password", { email })
        if (res.data.success)
            return { success: true, data: {} }
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function postResetOtp(code: string) {
    try {
        const res = await makePostRequest("/users/validate-reset-password-otp", { code })
        if (!res.data.success)
            throw new Error(res.data.error)
        return {
            success: true, data: {}
        }

    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function postOtpLogin(code: string) {
    try {
        const res = await makePostRequest("/users/validate-otp", { code })
        if (!res.data.success)
            throw new Error(res.data.error)
        return {
            success: true, data: res.data.data
        }

    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err).message } };
    }
}

async function getPDFReport(examId: string, examComment: string) {
    try {
        const res = await makePostRequest(`/exams/get-exam-report/${examId}`, { examComment })
        return { success: true, data: res.data };
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } };
    }
}

async function generatePDFReport(examId: string, signatureString: string) {
    try {
        const res = await makePostRequest(
            `/exams/generate-exam-report/${examId}`,
            { signatureString }
        );
        return { success: true, data: res.data };
    } catch (error) {
        return {
            success: false,
            data: { errorMessage: getErrorMessage(error).message },
        };
    }
}

async function getAbout() {
    try {
        const res = await makeGetRequest("get-about")
        return res.data
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } }
    }
}

async function getContact() {
    try {
        const res = await makeGetRequest("get-contact-us")
        return res.data
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } }
    }
}

async function getSupport() {
    try {
        const res = await makeGetRequest("get-support")
        return res.data
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } }
    }
}

async function updateUserProfile(body: {
    username: string,
    firstName: string,
    lastName: string,
    middleName: string,
    position: string,
    phoneNumber: string,
    password: string,
}) {
    try {
        const res = await makePostRequest("users/update-user-profile", body)
        return res.data
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } }
    }
}

async function updateUserSettings(body: {
    systemLanguage: string,
    predefinedEmails: string[],
}) {
    try {
        const res = await makePostRequest("user-info/user-settings", body)
        return res.data
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error).message } }
    }
}

export {
    postLogin,
    updateUserProfile,
    getPDFReport,
    updateUserSettings,
    generatePDFReport,
    getContact,
    getSupport,
    getAbout,
    postOtpLogin,
    getAllBranchPatientsWithClinical,
    postSetFirstPassword,
    postPersonalInfo,
    getUserInfo,
    createPatientClinical,
    getPatientClinical,
    getAllBranchPatients,
    getPatient,
    getBranchPatientCounts,
    createPatient,
    getSitesData,
    createNewSite,
    updateSite,
    deleteSite,
    getUserByEmailForSite,
    getUsersData,
    createUser,
    updateUser,
    getUserDataForCreation,
    deleteUsers,
    updatePatient,
    updatePatientClinical,
    getDevicesList,
    getDevicesDataForCreation,
    createDevice,
    updateDevice,
    deleteDevice,
    deletePatients,
    logoutRequest,
    exportData,
    forgetPassword,
    postResetOtp,
    getDataForPersonalInfo
};