import { create } from "zustand";

interface MenuStore {
  isCollapsed: boolean; 
  isMounted: boolean;
  toggleMenuMounted: () => void;
  toggleMenuCollapsed: () => void; 
}

export const useMenuStore = create<MenuStore>((set) => ({
  isCollapsed: false,
  toggleMenuCollapsed: () =>
    set((state) => ({
      isCollapsed: !state.isCollapsed,
    })),
  isMounted: false,
  toggleMenuMounted: () =>
    set((state) => ({
      isMounted: !state.isMounted,
    })),
}));