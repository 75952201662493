import React, { FC, useEffect, useRef, useState } from "react";
import "../../styles/homePage/table/table.scss";
import TableFilters, { TableFiltersProps } from "./TableFilters";
import SortTable, { SortTableProps } from "./SortTable";
import { TableProps, TableRowBase } from "../../types/Table.interface";
import TableRow from "./TableRow";

const Table = <T extends TableRowBase>(
  props: TableProps<T> & TableFiltersProps & Partial<SortTableProps>
) => {
  const {
    tableHeadline,
    trashAction,
    filterAction,
    searchValue,
    setSearchValue,
    searchPlaceholder,
    buttonText,
    buttonAction,
    tableData,
    setSortKey,
    sortKey,
    sortKeysArray,
    sortOrder,
    setSortOrder,
    onRowClick,
    onCheckBoxClick,
    filtersText,
    createPath,
    hasMore,
    isFetchingMore,
    fetchMore,
    exportAction,
    isExport,
    handleCheckAll,
  } = props;

  const tableWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!tableWrapperRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableWrapperRef.current;

      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        hasMore &&
        !isFetchingMore
      ) {
        fetchMore && fetchMore();
      }
    };

    const currentRef = tableWrapperRef.current;
    currentRef?.addEventListener("scroll", handleScroll);

    return () => {
      currentRef?.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore, isFetchingMore, fetchMore]);

  return (
    <div className="table-main-container">
      <div className="filters-container">
        <TableFilters
          tableHeadline={tableHeadline}
          trashAction={trashAction}
          filterAction={filterAction}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchPlaceholder={searchPlaceholder}
          buttonText={buttonText}
          buttonAction={buttonAction}
          exportAction={exportAction}
          isExport={isExport}
          filtersText={filtersText}
          createPath={createPath}
        />
      </div>
      <div className="sort-wrapper">
        <SortTable
          setSortOrder={setSortOrder!}
          sortOrder={sortOrder!}
          sortKeysArray={sortKeysArray!}
          setSortKey={setSortKey!}
          sortKey={sortKey!}
          handleCheckAll={(e) => {
            handleCheckAll && handleCheckAll(e);
          }}
        />
      </div>
      <div className="table-wrapper pb-3" ref={tableWrapperRef}>
        {tableData.map((val, index) => (
          <TableRow
            row={val}
            key={`${index}`}
            checkBoxClick={(row) => {
              onCheckBoxClick && onCheckBoxClick(row);
            }}
            onRowClick={(row) => onRowClick && onRowClick(row)}
          />
        ))}
      </div>
    </div>
  );
};

export default Table;
