export function formatDate(dateString: string, withTime?:boolean): string {
    const date = new Date(dateString);
  
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    let dateToReturn = `${day}/${month}/${year}`;
    dateToReturn = withTime ? `${dateToReturn} - ${hours}:${minutes}` : dateToReturn;
    return dateToReturn;
  }
