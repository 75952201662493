import React, { FunctionComponent, ReactNode } from "react";
import "../../../styles/components/DynamicModal.scss";
import { ReactComponent as CloseIcon } from "../../../common/assats/icons/closeXIcon.svg";
import ButtonCustom from "../Button";
import { useTranslation } from "react-i18next";

interface DynamicModalProps {
  title: string;
  icon: React.ReactNode;
  subtext: string;
  onClose: () => void; // Close button handler
  children?: ReactNode; // Dynamic children to render inside the modal
  buttonText?: string; // Optional button context
  onButtonClick?: () => void; // Optional button handler
}

const DynamicModal: React.FC<DynamicModalProps> = ({
  title,
  icon,
  subtext,
  onClose,
  children,
  buttonText,
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="modal-overlay">
      <div className={`base-modal ${children ? "modal-content-no-children" : "modal-content"}`}>
        <div className="modal-header">
          <div className="modal-icon">{icon}</div>
          <button className="modal-close" onClick={onClose}>
              <CloseIcon />
          </button>
        </div>

        <div className="modal-body">
          <h2>{title}</h2>
          <p>{subtext}</p>
          {children && <div className="modal-children">{children}</div>}
        </div>

        {buttonText && (
          <div className="modal-footer">
            <ButtonCustom
              type="button"
              onClick={onButtonClick}
              className="btn-primary btn-lg"
              context={t(buttonText)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicModal;
