import React, { useEffect } from "react";
import "../../../styles/components/TextInput.scss";
import { useTranslation } from "react-i18next";

interface CustomTextInputProps {
  value: string | number;
  required?: boolean;
  showErrors?: boolean;
  name: string;
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  handleFocus?: React.FocusEventHandler<HTMLInputElement>;
  label?: string;
  suffix?: string;
  id?: string;
  autoComplete?: boolean;
  handleBlur?: (name: string) => void;
  withPlaceHolder?: boolean;
  labelClass?: string;
  error?: string;
  translateErrors?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const CustomTextInput: React.FC<CustomTextInputProps> = (props) => {
  const { t } = useTranslation();
  const {
    name,
    value,
    required,
    showErrors,
    handleInputChange,
    handleFocus,
    error,
    label,
    suffix,
    autoComplete = false,
    id,
    handleBlur,
    withPlaceHolder = true,
    labelClass,
    translateErrors,
    icon,
  } = props;

  const getInputClassName = () => {
    if (showErrors && error) {
      return "form-control custom-input is-invalid";
    }
    return `form-control custom-input ${suffix ? "with-suffix" : ""}`;
  };

  const setLabel = value;
  return (
    <>
      <div
        className={`input-group custom-input-base ${
          setLabel ? "set-label" : ""
        } position-relative ${error && showErrors ? "invalid-field" : ""}`}
      >
        {withPlaceHolder && label && (
          <div className={labelClass?.length ? labelClass : `custom-input-label-base custom-input-label`}>
            {label}
            {required && <div className="required-asterisk">*</div>}
          </div>
        )}
         {icon && <span className="input-icon">{icon}</span>}
        <input
          type="text"
          onBlur={() => handleBlur && handleBlur(name)}
          className={getInputClassName()}
          name={name}
          onFocus={handleFocus}
          autoComplete={autoComplete ? "on" : "off"}
          id={id ?? name}
          value={value || ""}
          onChange={handleInputChange}
          disabled={props.disabled}
        />
      </div>
      {showErrors && error && (
        <div className="invalid-feedback">
          {translateErrors ? t(error) : error}
        </div>
      )}
    </>
  );
};

export default CustomTextInput;
