import React, { useState, useEffect } from "react";
import { Col, Form, Button } from "react-bootstrap";
import "../../styles/loginStyles/otp.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HalfScreenLogo from "../../components/HalfScreenLogo";
import { useTranslation } from "react-i18next";
import { useToast } from "../../common/contexts/useToast";
import {
  forgetPassword,
  postOtpLogin,
  postResetOtp,
} from "../../common/api/apiCalls";
import { NavigationPaths } from "../../common/consts/navigationPaths";
import useUserStore from "../../common/store/user.store";

const OTP = () => {
  const { t } = useTranslation();
  const { setUser } = useUserStore();
  const [code, setCode] = useState("");
  const location = useLocation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const locationState = location.state;
  const email = locationState?.email || "";
  const otpType = locationState?.otpType || "";

  useEffect(() => {
    if (!email) {
      navigate("/", { replace: true });
      showToast(
        "error",
        t("general.toast.error"),
        t("general.errors.generalError")
      );
    }
  }, [email, navigate, showToast, t]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!code) {
      showToast(
        "error",
        t("general.toast.error"),
        t("usersList.errors.otpIsRequired")
      );
      return;
    }

    let res;
    if (otpType === "login") {
      res = await postOtpLogin(code);
    } else {
      res = await postResetOtp(code);
    }
    if (res.success && otpType === "resetPassword") {
      navigate(NavigationPaths.RESET_PASSWORD, { state: { email } });
    } else if (res.success && otpType === "login") {
      setUser({
        id:res.data.userData.id,
        username:res.data.userData.username,
        role:[res.data.userData.role],
        fullName:res.data.userData.fullName,
        needToChangePassword:res.data.userData.needToChangePassword,
        firstName:res.data.userData.firstName,
        lastName:res.data.userData.lastName,
        middleName:res.data.userData.middleName,
        position:res.data.userData.position,
        phoneNumber:res.data.userData.phoneNumber,
        systemLanguage:res.data.userData.systemLanguage,
        predefinedEmails:res.data.userData.predefinedEmails,
        metrics:res.data.userData?.metrics,
      });
      navigate(NavigationPaths.DASHBOARD, { state: { email } });
    } else {
      showToast(
        "error",
        t("general.toast.error"),
        t(res.data?.errorMessage || "general.errors.generalError")
      );
      return;
    }
  };

  const resendOtp = async () => {
    const res = await forgetPassword(email);
    if (res?.success) {
      showToast(
        "success",
        t("general.toast.success"),
        t("general.otp.newOtpSent")
      );
    } else {
      showToast(
        "error",
        t("general.toast.error"),
        res?.data?.errorMessage || t("general.errors.generalError")
      );
      return;
    }
  };

  return (
    <HalfScreenLogo>
      <Col className="right-part d-flex align-items-center justify-content-center">
        <Form className="form-container otp-container" onSubmit={onSubmit}>
          <Form.Group className="title-group d-flex flex-column">
            <Form.Label className="global-big-label text-1o">
              {t("otp.SIGN_IN_TEXT_MAIN_TITLE")}
            </Form.Label>
            <Form.Label className="global-sub-label text-3o">
              {t("otp.SIGN_IN_TEXT_SUB_TITLE")}
            </Form.Label>
            <Form.Label className="global-sub-label text-3o">
              {email}
            </Form.Label>
          </Form.Group>

          <Form.Group className="group-spacing" controlId="formBasicCode">
            <Form.Control
              className="global-input"
              type="text"
              name="code"
              placeholder={t("otp.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER")}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </Form.Group>

          <div className="d-flex flex-column align-items-center">
            <Button
              className="global-button group-spacing"
              variant="primary"
              type="submit"
            >
              {t("otp.SIGN_IN_TEXT_BUTTON_1")}
            </Button>

            <span className="resend-link mb-2" onClick={resendOtp}>
              {t("general.otp.resendOtp")}
            </span>
            <Form.Group className="group-center">
              <Form.Label className="global-sub-label global-font-weight-600">
                {t("forget_pass.SIGN_IN_TEXT_BUTTON_2") + "   "}
              </Form.Label>

              <Link to="/">
                <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer space">
                  {t("forget_pass.SIGN_IN_TEXT_LABEL_1")}
                </Form.Label>
              </Link>
            </Form.Group>
          </div>
        </Form>
      </Col>
    </HalfScreenLogo>
  );
};

export default OTP;
