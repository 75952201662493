import React, { useEffect, useState } from "react";
import "../../../styles/components/Filter.scss";
import { ReactComponent as FiltersIcon } from "../../../common/assats/table/FiltersIcon.svg";
import { ReactComponent as CloseIcon } from "../../../common/assats/icons/filterXCloseIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../common/assats/icons/arrowIcon.svg";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFilters } from "../../../common/contexts/FiltersContext";
import CustomDateRangePicker from "../DatePicker/DateRange";
import { sysConfig } from "../../../config";
import { FilterOptionInterface } from "../../../common/interfaces/filtersObject.interface";


interface FilterProps {
  filtersObject?: FilterOptionInterface;
  handleFilterClose?: () => void;
  isOpen?: boolean;
  filterTrigger?: () => void;
}

// DEV ONLY
const tempFiltersObject: FilterOptionInterface = {
  status: {
    label: "Status",
    options: [
      { name: "before", label: "Before Exam" },
      { name: "after", label: "After Exam" },
    ],
  },
  date: {
    label: "Date",
    inputType: "date",
    options: [
      { name: "last7days", label: "Last 7 Days" },
      { name: "last30days", label: "Last 30 Days" },
      { name: "custom", label: "Custom" },
    ],
  },
};

const Filter: React.FC<FilterProps> = ({
  filtersObject = tempFiltersObject,
  handleFilterClose,
  isOpen = false,
  filterTrigger,
}) => {
  const [currentFilter, setCurrentFilter] = useState<
    keyof FilterOptionInterface | null
  >(null);

  const [temporaryFilters, setTemporaryFilters] = useState<{
    [key: string]: string | null;
  }>({});
  const [isDateRangeOpen, setIsDateRangeOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { activeFilters, filtersCount, handleFilters, clearFilters } =
    useFilters();

  const handleOptionClick = (
    filterName: string,
    optionName: string,
    isSelected: boolean,
    inputType?: string,
  ) => {
    if (inputType === "date" && optionName === "custom") {
      setTemporaryFilters((prev) => ({
        ...prev,
        [filterName]: isSelected ? optionName : null,
      }));
      setIsDateRangeOpen(isSelected);
    } else {
      // Apply regular filters
      handleFilters(filterName, optionName, isSelected);
      filterTrigger && filterTrigger();
    }
  };

  const submitCustomDate = (
    filterName: string,
    fromDate: string,
    toDate: string
  ) => {
    const dateRange = `${fromDate} - ${toDate}`;
  
    // Remove any existing date range for the filterName
    const updatedFilters = activeFilters[filterName]?.filter(
      (filter: string) => !sysConfig.RANGE_TIMESTAMP_REGEX.test(filter)
    );
  
    // Add the new date range
    handleFilters(filterName, dateRange, true, true);
  
    setTemporaryFilters((prev) => ({
      ...prev,
    }));
    filterTrigger && filterTrigger();
  };

  const clearFiltersHandler = () => {
    clearFilters();
    setTemporaryFilters({});
    setCurrentFilter(null);
    setIsDateRangeOpen(false);
    filterTrigger && filterTrigger();
  };

  const backToFilters = () => {
    setCurrentFilter(null);
    setIsDateRangeOpen(false);
    temporaryFilters[currentFilter as string] = null;
  };

  useEffect(() => {
    if (activeFilters[currentFilter as string]) {

      const hasTimestamp = activeFilters[currentFilter as string].some(
        (filter: string) => sysConfig.RANGE_TIMESTAMP_REGEX.test(filter)
      );

      if (hasTimestamp) {
        setIsDateRangeOpen(true);
        temporaryFilters[currentFilter as string] = "custom";
      }
    }
  }, [activeFilters, currentFilter]);


  const getDateRangeValue = (filterName: string) => {
    const rangeDate = activeFilters[filterName]?.find((filter: string) => { 
      return sysConfig.RANGE_TIMESTAMP_REGEX.test(filter)
    });
    return rangeDate;
  }

  return (
    <div className={`custom-filter-container ${isOpen ? "open" : ""}`}>
      <div className="filters-header">
        <div className="header-title">
          {currentFilter ? (
            <ArrowIcon
              style={{ transform: "rotate(180deg)", cursor: "pointer" }}
              onClick={backToFilters}
            />
          ) : (
            <FiltersIcon />
          )}
          {currentFilter ? filtersObject[currentFilter]?.label : "Filter"}
        </div>
        <div>
          <CloseIcon
            onClick={handleFilterClose}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="filter-options-container">
        {!currentFilter ? (
          <div className="filter-options">
            {Object.keys(filtersObject).map((filterName) => (
              <div
                className="filter-option"
                key={`${filterName}filterOption`}
                onClick={() => setCurrentFilter(filterName)}
              >
                <div className="option-label">
                  {filtersObject[filterName].label}
                </div>
                <div>
                  <ArrowIcon />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="embedded-filter-options">
            {filtersObject[currentFilter]?.options.map((filterOption) => (
              <div
                className="filter-option"
                key={`${filterOption.name}embeddedfilterOption`}
              >
                <Form.Check
                  inline
                  id={`${filterOption.name}-radio`}
                  checked={
                    activeFilters[currentFilter]?.includes(filterOption.name) ||
                    temporaryFilters[currentFilter] === filterOption.name ||
                    false
                  } // Mark as checked if it's in activeFilters or temporaryFilters
                  onChange={(e) => {
                    const isSelected = e.target.checked;
                    handleOptionClick(
                      currentFilter as string,
                      filterOption.name,
                      isSelected,
                      filtersObject[currentFilter]?.inputType
                    );
                  }}
                />
                <label htmlFor={`${filterOption.name}-radio`}>
                  {filterOption.label}
                </label>
              </div>
            ))}
          </div>
        )}
        {isDateRangeOpen && (
          <CustomDateRangePicker
            onSubmit={(fromDate: string, toDate: string) => {
              submitCustomDate(currentFilter as string, fromDate, toDate);
            }}
            dateRangeValue={getDateRangeValue(currentFilter as string)}
          />
        )}
      </div>
      <hr />
      <div className="filter-footer d-flex align-items-center justify-content-start mb-3 ms-3">
        <span>
          {filtersCount} {t("general.filters.title")}
        </span>
        {filtersCount ? (
          <span
            onClick={clearFiltersHandler}
            className="link pointer ms-auto me-3"
          >
            {t("general.filters.clear")}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default Filter;
